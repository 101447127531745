import { EntryTab } from '@/types/ui.types';
import { useEffect, useState } from 'react';
import { useDataStore } from '@/Providers/StoreProvider';
import { useVoiceRecording } from './useVoiceRecording';
import { useTakePictures } from './useTakePictures';
import { CardPhoto, ICard } from '@/types/card';

export const useDeviceRecording = ({
  activeTab,
  cardData,
  photos,
}: {
  activeTab: EntryTab;
  cardData: ICard | null;
  photos: CardPhoto[];
}) => {
  const [isAutoMode, setIsAutoMode] = useState(true);
  const [isAutoModeBlocked, setIsAutoModeBlocked] = useState(false);
  const { rootMediaDevicesStore } = useDataStore();

  const { isInited, init, clearStorageRecordingState, isRecordingActive } = rootMediaDevicesStore;

  const { recorderControls, asyncStopPlayerRecording } = useVoiceRecording({
    cardId: cardData?.id,
  });

  const { takePicture, autoModePhotos, manualModePhotoIds } = useTakePictures({
    isAutoMode,
    setIsAutoMode,
    setIsAutoModeBlocked,
    cardData,
    photos,
    activeTab,
  });

  useEffect(() => {
    if (activeTab === 'deviceRecording' && !isInited) {
      void init({ localStorageId: 'deviceRecordingTab' });
    }
  }, [activeTab, isInited, init]);

  useEffect(() => {
    const onBeforeUnload = () => {
      clearStorageRecordingState();
    };

    window.addEventListener('beforeunload', onBeforeUnload);

    return () => window.removeEventListener('beforeunload', onBeforeUnload);
  }, [clearStorageRecordingState]);

  useEffect(() => {
    if (isRecordingActive) {
      setIsAutoMode(true);
      setIsAutoModeBlocked(false);
    }
  }, [isRecordingActive]);

  return {
    isAutoMode,
    setIsAutoMode,
    recorderControls,
    asyncStopPlayerRecording,
    takePicture,
    autoModePhotosRef: autoModePhotos,
    manualModePhotoIdsRef: manualModePhotoIds,
    isAutoModeBlocked,
  };
};
