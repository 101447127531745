import { useDataStore } from '@/Providers/StoreProvider';
import { appColors } from '@/Providers/ThemeProvider/colors';
import { MediaDevicesStore } from '@/stores/WebRTC/MediaDevicesStore';
import { InputLabel, Stack, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Form,
  Icon,
  icons,
  Modal,
  SelectField,
  SelectFieldValue,
  UiKitColor,
  useForm,
} from 'stc-ui-kit';

interface IFormData {
  video: SelectFieldValue;
  sound: SelectFieldValue;
}

export function DeviceSetupModal({ onClose }: { onClose: () => void }) {
  const { rootMediaDevicesStore } = useDataStore();

  const {
    allDevicesByType,
    selectedAudioInputDeviceId,
    selectedVideoInputDeviceId,
    setSelectedAudioInputDeviceId,
    setSelectedVideoInputDeviceId,
    getMediaDeviceStream,
  } = rootMediaDevicesStore;

  const { t } = useTranslation();
  const [videoDeviceId, setVideoDeviceId] = useState(selectedVideoInputDeviceId);
  const [audioDeviceId, setAudioDeviceId] = useState(selectedAudioInputDeviceId);
  const [micLevel, setMicLevel] = useState(0);
  const micRef = useRef<MediaStream>();
  const videoRef = useRef<HTMLVideoElement>(null);

  const { form } = useForm<IFormData>({
    defaultValues: {
      video: selectedVideoInputDeviceId,
      sound: selectedAudioInputDeviceId,
    },
  });

  useEffect(() => {
    if (!videoDeviceId) {
      if (videoRef.current) {
        videoRef.current.srcObject = null;
      }

      return;
    }

    const getVideoSrc = async () => {
      const stream = await getMediaDeviceStream('video', videoDeviceId);
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    };

    void getVideoSrc();
  }, [videoDeviceId, getMediaDeviceStream]);

  const clearMicStream = () => {
    if (micRef.current) {
      micRef.current.getTracks().forEach((track) => track.stop());
    }
  };

  useEffect(() => {
    if (!audioDeviceId) {
      clearMicStream();

      return;
    }

    const getAudioVolume = async () => {
      const stream = await getMediaDeviceStream('audio', audioDeviceId);
      micRef.current = stream;
      const audioContext = new window.AudioContext();
      const microphone = audioContext.createMediaStreamSource(stream);
      const analyser = audioContext.createAnalyser();

      microphone.connect(analyser);
      analyser.fftSize = 256;
      const bufferLength = analyser.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);

      const updateMicLevel = () => {
        analyser.getByteFrequencyData(dataArray);
        const sum = dataArray.reduce((acc, value) => acc + value, 0);
        const average = sum / dataArray.length;
        setMicLevel(average);
        requestAnimationFrame(updateMicLevel);
      };

      updateMicLevel();
    };

    void getAudioVolume();

    return () => clearMicStream();
  }, [audioDeviceId, getMediaDeviceStream]);

  const videoDeviceItems = allDevicesByType.video.map((device) => ({
    id: device.deviceId,
    label: device.label,
  }));

  const audioDeviceItems = allDevicesByType.audio.map((device) => ({
    id: device.deviceId,
    label: device.label,
  }));

  const onSubmit = (data: IFormData) => {
    setSelectedVideoInputDeviceId(data.video || '');
    setSelectedAudioInputDeviceId(data.sound || '');
    onClose();
  };

  return (
    <Modal
      title={t('cards.devicesSetup')}
      onClose={onClose}
      data-testid=''
      buttonCloseText={t('close')}
      buttonConfirmText={t('apply')}
      isDefaultContentPadding={false}
      onConfirm={form.handleSubmit(onSubmit)}
    >
      <Form form={form} data-testid=''>
        <Stack direction={'row'} gap={3} width={'640px'} padding={2}>
          <Stack width={'285px'}>
            <Typography fontWeight={700} fontSize={'14px'}>
              {t('cards.video')}
            </Typography>

            <SelectField
              data-testid=''
              onChange={(value) => setVideoDeviceId(value || undefined)}
              id='video'
              label={t('cards.camera')}
              placeholder={t('cards.cameraNotSelected')}
              options={videoDeviceItems}
              getSelectedValuePrefix={() => (
                <Icon icon={icons.IconPhoto} iconColor={UiKitColor.Teal700} />
              )}
            />

            <Stack
              sx={{
                marginTop: '10px',
                borderRadius: '4px',
                height: '210px',
                alignItems: 'center',
                justifyContent: 'center',
                border: `1px solid ${appColors.darkThemeBorder}`,
              }}
            >
              {!!videoDeviceId && (
                <video style={{ width: '100%', height: '100%' }} ref={videoRef} autoPlay />
              )}

              {!videoDeviceId && (
                <Typography fontSize={'16px'}>{t('cards.deviceNotSelected')}</Typography>
              )}
            </Stack>
          </Stack>

          <Stack width={'300px'}>
            <Typography fontWeight={700} fontSize={'14px'}>
              {t('cards.sound')}
            </Typography>

            <SelectField
              data-testid=''
              onChange={(value) => setAudioDeviceId(value || undefined)}
              id='sound'
              label={t('cards.microphone')}
              placeholder={t('cards.microphoneNotSelected')}
              options={audioDeviceItems}
              getSelectedValuePrefix={() => (
                <Icon icon={icons.IconMicrophone} iconColor={UiKitColor.Teal700} />
              )}
            />

            <InputLabel disabled={!audioDeviceId}>{t('cards.sensitivity')}</InputLabel>
            <Stack direction={'row'} gap={1}>
              {Array.from({ length: 10 }).map((_, i) => {
                const getColor = () => {
                  if (!audioDeviceId) return appColors.darkLightGrey;

                  return Math.floor(micLevel % 10) >= i ? appColors.cyan : 'white';
                };

                return (
                  <div
                    key={i}
                    style={{
                      width: '24px',
                      height: '4px',
                      backgroundColor: getColor(),
                    }}
                  />
                );
              })}
            </Stack>
          </Stack>
        </Stack>
      </Form>
    </Modal>
  );
}
