import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmationType, useConfirmationModal } from 'stc-ui-kit';
import { cardsService } from '@/services/cards.service';
import { EntryTab } from '@/types/ui.types';
import { useDataStore } from '@/Providers/StoreProvider';
import { FileGroupType } from '@/types/file';
import { getTabByFileGroup } from '../utils/getTabByFileGroup';

interface UseDeleteFilesProps {
  cardId: number;
  activeTab: EntryTab;
  selectedEntitiesIds: number[];
  mainModelLinkId: number | null;
}

export const useDeleteFiles = ({
  cardId,
  activeTab,
  selectedEntitiesIds,
  mainModelLinkId,
}: UseDeleteFilesProps) => {
  const { t } = useTranslation();
  const { showConfirmationModal } = useConfirmationModal();
  const { uiStore } = useDataStore();

  const getDeleteModalText = useCallback(
    (ids: number[], tab: EntryTab) => {
      const isMainEntityInSelected = mainModelLinkId ? ids.includes(mainModelLinkId) : false;

      let title = '';
      let description = '';

      switch (tab) {
        case 'face':
          title = ids.length > 1 ? t('cards.deletePhotosTitle') : t('cards.deletePhotoTitle');
          break;
        case 'voice':
          title = ids.length > 1 ? t('cards.deleteAudiosTitle') : t('cards.deleteAudioTitle');
          break;
        case 'documents':
          title = ids.length > 1 ? t('cards.deleteDocumentsTitle') : t('cards.deleteDocumentTitle');
          break;

        case 'attachedFiles':
          title = ids.length > 1 ? t('cards.deleteFilesTitle') : t('cards.deleteFileTitle');
          break;

        default:
          title = ids.length > 1 ? t('cards.deleteFilesTitle') : t('cards.deleteFileTitle');
      }

      switch (tab) {
        case 'face':
          if (isMainEntityInSelected) {
            description = t('cards.deleteMainPhotoDesc');
            break;
          }

          description =
            ids.length > 1 ? t('cards.deleteAddedInCardPhotos') : t('cards.deleteAddedInCardPhoto');
          break;

        case 'voice':
          description =
            ids.length > 1 ? t('cards.deleteAddedInCardAudios') : t('cards.deleteAddedInCardAudio');
          break;

        case 'documents':
          if (isMainEntityInSelected) {
            description = t('cards.deleteDocumentWithMainPhoto');
            break;
          }
          description =
            ids.length > 1
              ? t('cards.deleteDocumentsConfirmation')
              : t('cards.deleteDocumentConfirmation');
          break;

        case 'attachedFiles':
          description =
            ids.length > 1 ? t('cards.deleteFilesConfirmation') : t('cards.deleteFileConfirmation');
          break;

        default:
          description =
            ids.length > 1 ? t('cards.deleteFilesConfirmation') : t('cards.deleteFileConfirmation');
      }

      return { title, description };
    },
    [t, mainModelLinkId]
  );

  const onDeleteConfirm = useCallback(
    async (ids: number[], resetSelection: boolean) => {
      try {
        await cardsService.deleteMediaFilesFromCard({
          cardId: cardId,
          ids: ids,
        });

        if (resetSelection) {
          uiStore.setSelectedEntities(cardId, activeTab, []);
        }
      } catch (error) {
        console.error(error);
      }
    },
    [cardId, uiStore, activeTab]
  );

  const deleteFiles = useCallback(
    async (ids?: number[], fileGroup?: FileGroupType) => {
      const idsToDelete = ids || selectedEntitiesIds;
      const resetSelection = !ids;

      const tab = getTabByFileGroup(fileGroup) || activeTab;

      const text = getDeleteModalText(idsToDelete, tab);

      const submitType = await showConfirmationModal({
        title: text.title,
        text: text.description,
        note: t('thisActionCannotBeUndone'),
        primaryButtonText: t('delete'),
        cancelButtonText: t('cancel'),
      });
      if (submitType === ConfirmationType.PrimaryAction) {
        await onDeleteConfirm(idsToDelete, resetSelection);
      }
    },
    [selectedEntitiesIds, activeTab, getDeleteModalText, showConfirmationModal, t, onDeleteConfirm]
  );

  return { deleteFiles };
};
