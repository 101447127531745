import { useEffect, useRef } from 'react';
import { EventStreamContentType, fetchEventSource } from '@microsoft/fetch-event-source';
import { useDataStore } from '@/Providers/StoreProvider';
import { makeURL } from '@/services/root.api.service';
import { SseMessage, SseMessageType } from '@/types/sse';

export const useInitAppSse = () => {
  const { userStore, sseStore } = useDataStore();
  const isError = useRef(false);

  useEffect(() => {
    const token = userStore.userData.t4f_token;
    if (!token) return;

    const controller = new AbortController();
    const { signal } = controller;

    const initSse = async () => {
      await fetchEventSource(makeURL('card/events'), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        onmessage(event) {
          try {
            const parsedData: SseMessage = JSON.parse(event.data);

            if (parsedData.type !== SseMessageType.Ping) {
              sseStore.setMessage(parsedData);
              // do not delete, very useful for debug
              // console.log(parsedData);
            }
          } catch (error) {
            console.error('>>> sse message parsing error');
          }
        },
        signal,
        async onopen(response) {
          await new Promise<void>((res) => res());

          if (response.ok && response.headers.get('content-type') === EventStreamContentType) {
            if (isError.current) {
              isError.current = false;
              sseStore.setIsReconnectedAfterError(true);
            }

            return;
          } else {
            console.error('>>> on open: ', response.status, response.statusText, response.body);
          }
        },
        onerror(err) {
          console.error('>>> error: ', err);
          isError.current = true;
        },
      });
    };

    void initSse();

    return () => controller.abort();
  }, [userStore.userData.t4f_token, sseStore]);
};
