import { ICard } from '@/types/card';
import { CardTemplate } from '@/types/cardTemplate';
import { PropsWithChildren, createContext, useEffect, useState } from 'react';
import { useCardPhotos } from '../hooks/useCardPhotos';
import { cardsService } from '@/services/cards.service';
import { useSnackBarMessage } from '@/hooks/useSnackBarMessage';
import { observer } from 'mobx-react';
import { useDataStore } from '@/Providers/StoreProvider';
import { getFileGroupByTab } from '../utils/getFileGroupByTab';
import { EntryTab } from '@/types/ui.types';
import { fetchFileLinks } from '../utils/fetchFileLinks';
import { sortObjectsByCreatedTime } from '../utils/sortObjectsByCreatedTime';
import { EFileProcessStatus, FileGroupType, IFileLink } from '@/types/file';
import { useDeleteFiles } from '../hooks/useDeleteFiles';
import { useCreateNewEntryFromFace } from '../hooks/useCreateNewEntryFromFace';
import { useCardFileSseMessages } from '../hooks/useCardFileSseMessages';
import { ConditionType, FormReturn } from 'stc-ui-kit';
import { FieldValues } from 'react-hook-form';
import { useDeviceRecording } from '../hooks/useDeviceRecording';
import { useUnsavedChangesModal } from '../hooks/useUnsavedChangesModal';
import { useTranslation } from 'react-i18next';
import { RECORD_SESSION_ID_FIELD } from '../constants';
import { AdditionalConditions, IApiFieldFilter } from '@/types';

interface ContextProps extends PropsWithChildren {
  selectedTemplate: CardTemplate | null;
  formHooks: FormReturn<FieldValues>;
  cardData: ICard | null;
  saveCardData?: () => Promise<unknown>;
  refetchCardData: (keepDirty?: boolean) => Promise<void>;
  hideAvatar?: boolean;
}

interface ContextValues extends ContextProps {
  photosData: ReturnType<typeof useCardPhotos>;
  isFilesLoading: boolean;
  setCardModel: (modelId: number) => Promise<void>;
  fileLinks: IFileLink[];
  deleteFiles: (ids?: number[], fileGroup?: FileGroupType) => Promise<void>;
  createNewEntry: ({ modelId, fileProcessId }: { modelId: number; fileProcessId: number }) => void;
  deviceRecordingData: ReturnType<typeof useDeviceRecording>;
}

export const CardCreationContext = createContext<ContextValues | null>(null);

export const CardCreationContextProvider = observer(
  ({
    formHooks,
    selectedTemplate,
    children,
    cardData,
    saveCardData,
    refetchCardData,
    ...rest
  }: ContextProps) => {
    const { error } = useSnackBarMessage();
    const { uiStore, rootMediaDevicesStore } = useDataStore();
    const { t } = useTranslation();
    const [isFilesLoading, setIsFilesLoading] = useState(false);
    const [fileLinks, setFileLinks] = useState<IFileLink[]>([]);

    const { isRecordingActive, toggleIsRecording, currentRecordSessionId } = rootMediaDevicesStore;

    const photosData = useCardPhotos({ cardData });
    const { loadAndProcessPhotos, sortPhotos } = photosData;

    const { createNewEntry } = useCreateNewEntryFromFace();

    const openedCard = cardData ? uiStore.getOpenedCard(cardData.id) : null;
    const activeTab: EntryTab =
      (openedCard?.activeTab === 'biometricModels'
        ? openedCard?.activeSideMenuItem
        : openedCard?.activeTab) || 'voice';
    const sortOrder = openedCard?.sortOrder.get(activeTab) || 'DESC';
    const selectedEntitiesIds = openedCard?.selectedEntities.get(activeTab) || [];

    const deviceRecordingData = useDeviceRecording({
      activeTab,
      cardData,
      photos: photosData.photos,
    });

    useUnsavedChangesModal([
      {
        when: isRecordingActive,
        onSave: async () => {
          toggleIsRecording();
          await deviceRecordingData.asyncStopPlayerRecording();
        },
        confirmationModalProps: {
          title: t('warning'),
          text: t('cards.biometricsCollectionModeIsActive'),
          note: t('cards.stopRecordingOnCloseConfirmation'),
          primaryButtonText: t('stop'),
          cancelButtonText: t('cancel'),
          isCriticalAction: false,
        },
      },
      {
        when: formHooks.formState.isDirty,
        onSave: saveCardData,
        confirmationModalProps: {
          title: t('cards.closeCardTitle'),
          text: t('cards.unsavedChangesConfirmation'),
          note: t('thisActionCannotBeUndone'),
          primaryButtonText: t('saveAndClose'),
          secondaryButtonText: t('close'),
          cancelButtonText: t('cancel'),
          isCriticalAction: false,
          isSecondaryCriticalAction: true,
        },
      },
    ]);

    useEffect(() => {
      void refetchTabData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab, cardData?.id, isRecordingActive]);

    useEffect(() => {
      setFileLinks((prev) => [...sortObjectsByCreatedTime(prev, sortOrder)]);
      if (activeTab === 'face') {
        sortPhotos(sortOrder);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortOrder]);

    useEffect(() => {
      const refetchCardPhotos = async () => {
        if (!cardData?.id) return;
        setIsFilesLoading(true);
        const files = await fetchFileLinks(cardData.id, 'FACE');
        if (!files) {
          setIsFilesLoading(false);

          return;
        }

        const sortedFileProcesses = sortObjectsByCreatedTime(files, sortOrder);
        await loadAndProcessPhotos(sortedFileProcesses);
        setIsFilesLoading(false);
      };

      void refetchCardPhotos();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cardData?.faceModel?.id, cardData?.id]);

    // on face tab show loader until all files are completed
    useEffect(() => {
      if (activeTab === 'face') {
        setIsFilesLoading(
          fileLinks.some(
            (l) => l.status === EFileProcessStatus.NEW || l.status === EFileProcessStatus.INPROGRESS
          )
        );
      }
    }, [fileLinks, activeTab]);

    const refetchTabData = async () => {
      if (!cardData?.id) return;

      setIsFilesLoading(true);

      const fileGroup = getFileGroupByTab(activeTab, isRecordingActive);
      let additionalFilters: IApiFieldFilter[] | undefined = undefined;

      if (activeTab === 'deviceRecording') {
        additionalFilters = isRecordingActive
          ? [
              {
                fieldId: RECORD_SESSION_ID_FIELD,
                targetValues: [currentRecordSessionId],
                predicate: ConditionType.Equals,
              },
            ]
          : [
              {
                fieldId: RECORD_SESSION_ID_FIELD,
                predicate: AdditionalConditions.NotNull,
              },
            ];
      }

      const files = (await fetchFileLinks(cardData.id, fileGroup, additionalFilters)) || [];

      const sortedFileProcesses = sortObjectsByCreatedTime(files, sortOrder);
      setFileLinks(sortedFileProcesses);
      setIsFilesLoading(false);

      void updateTabUiStore(sortedFileProcesses, cardData.id);
    };

    const updateTabUiStore = async (fileProcesses: IFileLink[], cardId: number) => {
      switch (activeTab) {
        case 'face':
          await loadAndProcessPhotos(fileProcesses);
          break;

        case 'deviceRecording':
          if (isRecordingActive) {
            /**
             * в случае, если запись голоса завершилась из-за превышения лимита времени,
             * среди fileProcesses будет аудио (в общем случае там только фото).
             */
            await loadAndProcessPhotos(fileProcesses.filter((fp) => fp.type === 'img'));
          }
      }

      uiStore.setEntities(
        cardId,
        activeTab,
        fileProcesses.map((f) => {
          return {
            path: f.file.path,
            id: f.id,
            name: f.file.path.split('/').pop() || '',
          };
        })
      );
    };

    useCardFileSseMessages({
      cardId: cardData?.id,
      activeTab,
      refetchCardData,
      fileLinks,
      setFileLinks,
      updateTabUiStore,
      refetchTabData,
      formHooks,
    });

    const setCardModel = async (modelId: number) => {
      try {
        if (!cardData) return;

        const { errorDescription } = await cardsService.setCardModel({
          cardId: cardData.id,
          modelId,
        });
        if (errorDescription?.description) {
          return error(errorDescription.description);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const mainModelLinkId = (() => {
      const cardFaceModelLinkId = cardData?.faceModel?.fileModelLinks[0]?.cardMediaFileLinkId;
      const cardVoiceModelLinkId = cardData?.voiceModel?.fileModelLinks[0]?.cardMediaFileLinkId;

      switch (activeTab) {
        case 'face':
          return cardFaceModelLinkId || null;
        case 'voice':
          return cardVoiceModelLinkId || null;
        case 'documents':
          return cardFaceModelLinkId || null;
        default:
          return null;
      }
    })();

    const { deleteFiles } = useDeleteFiles({
      cardId: cardData?.id || 0,
      activeTab,
      selectedEntitiesIds,
      mainModelLinkId,
    });

    return (
      <CardCreationContext.Provider
        value={{
          formHooks,
          selectedTemplate,
          cardData,
          photosData,
          refetchCardData,
          setCardModel,
          fileLinks,
          isFilesLoading,
          deleteFiles,
          createNewEntry,
          deviceRecordingData,
          ...rest,
        }}
      >
        {children}
      </CardCreationContext.Provider>
    );
  }
);
