import { useTranslation } from 'react-i18next';
import { ButtonStyle, Modal } from 'stc-ui-kit';
import { makeStyles } from '@/hooks/makeStyles';
import { Questionnaire } from '@/types/cardTemplate';
import { useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { appColors } from '@/Providers/ThemeProvider/colors';

interface Props {
  questionnaire: Questionnaire;
  onClose: () => void;
}

const useStyles = makeStyles()(() => ({
  modal: {
    width: '640px',
    minHeight: '260px',
  },
}));

export const QuestionnaireModal: React.FC<Props> = ({ onClose, questionnaire }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [index, setIndex] = useState(0);
  const count = questionnaire.questions.length;

  const modalProps = {
    onClose: onClose,
    title: `${t('cards.questionnaire')} «${questionnaire.title}»`,
    buttonCloseText: t('close'),
    className: classes.modal,
    buttonConfirmText: t('cards.previousQuestion'),
    buttonConfirmStyle: ButtonStyle.Secondary,
    isConfirmButtonHidden: index === 0,
    onConfirm: () => {
      setIndex((prevIndex) => prevIndex - 1);
    },
    ...(index + 1 < count && {
      secondaryButtonText: t('cards.nextQuestion'),
      onSecondaryButtonClick: () => {
        setIndex((prevIndex) => prevIndex + 1);
      },
    }),
  };

  return (
    <Modal data-testid='' {...modalProps}>
      <Stack alignItems={'center'} justifyContent={'center'} spacing={1} height={'100%'}>
        <Typography fontSize={'14px'} fontWeight={700}>
          {questionnaire.questions[index]}
        </Typography>
        <Typography fontSize={'14px'} fontWeight={500} color={appColors.darkLightGrey}>
          {t('cards.questionsCounter', { index: index + 1, count: count })}
        </Typography>
      </Stack>
    </Modal>
  );
};
