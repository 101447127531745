import { useImageSize } from '@/hooks/useImageSize';
import { CardPhoto } from '@/types/card';
import { Box, Stack, Typography } from '@mui/material';
import React, { useRef } from 'react';
import { FaceBox } from './FaceBox';
import { PhotoContextMenu } from './PhotoContextMenu';
import { Icon, UiKitColor, icons } from 'stc-ui-kit';
import { appColors } from '@/Providers/ThemeProvider/colors';
import { useTranslation } from 'react-i18next';
import { useCardCreationContext } from '@/modules/multipass/CardCreation/hooks/useCardCreationContext';
import { ModelQualityIconComponent } from '@/modules/multipass/components/icons/ModelQualityIconComponent';
import { useImageErrorPlaceholder } from '@/hooks/useImageErrorPlaceholder';

interface Props {
  photo: CardPhoto;
  onCarouselClose: () => void;
}

export const ImageWithFaceModels: React.FC<Props> = ({ photo, onCarouselClose }) => {
  const { createNewEntry } = useCardCreationContext();
  const ref = useRef<HTMLImageElement>(null);
  const { t } = useTranslation();
  const isMultipleFaces = photo.faceData.length > 1;
  const isSingleFace = photo.faceData.length === 1;

  const { imagePosition, ratio, changeImageSizes } = useImageSize({
    imageRef: ref,
  });

  const { onError } = useImageErrorPlaceholder();

  const onCreateNewEntry = (modelId: number) => {
    createNewEntry({
      modelId: modelId,
      fileProcessId: photo.fileProcessId,
    });
  };

  return (
    <Box maxWidth={'100%'} maxHeight={'100%'} height={'100%'} position='relative'>
      {isMultipleFaces &&
        photo.faceData.map((face, i) => {
          if (!ratio) return null;

          return (
            <FaceBox
              key={i}
              imagePosition={imagePosition}
              isMultipleFaces={isMultipleFaces}
              face={face}
              imageRatio={ratio}
              createNewEntry={onCreateNewEntry}
            />
          );
        })}

      <img
        src={photo.path}
        alt=''
        style={{
          objectFit: 'contain',
          height: '100%',
          maxWidth: '100%',
        }}
        onError={onError}
        ref={ref}
        onLoad={(e) => {
          const image = e.target as HTMLImageElement;
          changeImageSizes(image);
        }}
      />

      <Box
        position={'absolute'}
        top={'16px'}
        left={imagePosition.x + imagePosition.width - (isSingleFace ? 64 : 32)}
        gap={'8px'}
        display={'flex'}
        flexDirection={'row'}
      >
        {isSingleFace && (
          <ModelQualityIconComponent
            quality={photo.faceData[0]?.quality}
            type='img'
            portraitCharacteristics={photo.faceData[0]?.portraitCharacteristics}
          />
        )}
        <PhotoContextMenu photo={photo} onCarouselClose={onCarouselClose} />
      </Box>

      {photo.isMain && (
        <Stack
          direction={'row'}
          spacing={1}
          alignItems={'center'}
          position={'absolute'}
          top={'16px'}
          left={imagePosition.x + 32}
        >
          <Icon icon={icons.IconTaskCompleted} iconColor={UiKitColor.Teal700} />
          <Typography fontSize={'12px'} fontWeight={500} color={appColors.black600}>
            {t('cards.faceModel')}
          </Typography>
        </Stack>
      )}
    </Box>
  );
};
