import { PortraitCharacteristics } from '@/types/model';
import { useTranslation } from 'react-i18next';
import { Icon, Modal } from 'stc-ui-kit';
import { Stack, Typography } from '@mui/material';
import { ModelQualityIconComponent } from '../icons/ModelQualityIconComponent';
import { getModelQuality } from '../../utils/getModelQuality';
import { modelQualityToTitle, getIconByCharacteristicQuality } from '../../utils/modelQualityUtils';
import { makeStyles } from '@/hooks/makeStyles';

interface Props {
  onClose: () => void;
  quality: number;
  portraitCharacteristics: PortraitCharacteristics[];
}

const useStyles = makeStyles()(() => ({
  modal: {
    width: '430px',
    '& [class*="Modal-module__contentWrapper"]': {
      padding: '16px 16px 0px !important',
    },
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    height: '310px',
    maxHeight: '310px',
  },
  characteristicItem: {
    display: 'flex',
    alignItems: 'center',
    width: '50%',
    height: '18px',
    marginBottom: '16px',
  },
}));

export const PortraitCharacteristicsModal: React.FC<Props> = ({
  onClose,
  portraitCharacteristics,
  quality,
}) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <Modal
      onClose={onClose}
      data-testid=''
      title={t('portraitCharacteristics.title')}
      buttonCloseText={t('close')}
      isConfirmButtonHidden
      className={classes.modal}
    >
      <Stack direction={'row'} spacing={1} marginBottom={2}>
        <ModelQualityIconComponent quality={quality} type='img' />
        <Typography fontWeight={700} fontSize={'16px'}>
          {t(modelQualityToTitle[getModelQuality(quality)], 'Unknown')}
        </Typography>
      </Stack>

      <div className={classes.flexContainer}>
        {portraitCharacteristics.map((characteristic, index) => (
          <div className={classes.characteristicItem} key={index}>
            <Icon icon={getIconByCharacteristicQuality(characteristic.quality)} />
            <Typography fontWeight={700} fontSize={'14px'} marginLeft={1}>
              {t(`portraitCharacteristics.${characteristic.characteristic}`, 'Unknown')}
            </Typography>
          </div>
        ))}
      </div>
    </Modal>
  );
};
