import { Box, Stack, Typography } from '@mui/material';
import { useCardCreationContext } from '../../../hooks/useCardCreationContext';
import { appColors } from '@/Providers/ThemeProvider/colors';
import { EmptyPlaceholder, Icon, UiKitColor, icons } from 'stc-ui-kit';

import { makeStyles } from '@/hooks/makeStyles';
import { useTranslation } from 'react-i18next';
import { ReactComponent as NoImageSvg } from '@/assets/icons/noImagePlaceholder.svg';
import { useFullScreenCardCarousel } from '@/modules/multipass/hooks/useFullScreenCardCarousel';
import { CarouselPhotosContextProvider } from '@/modules/multipass/context/CarouselPhotosContext';
import { PhotoWrapper } from './PhotoWrapper';

const useStyles = makeStyles()(() => ({
  photoGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, 240px)',
    gap: '16px',
    width: '100%',
    padding: '16px',
  },
}));

export const FaceModels = () => {
  const { photosData } = useCardCreationContext();
  const { combinedPhotos, fullscreenCombinedPhotos } = photosData;

  const { classes } = useStyles();
  const { t } = useTranslation();
  const {
    carouselRender: fullScreenCarouselRender,
    openFullScreenCarousel,
    closeFullScreenCarousel,
  } = useFullScreenCardCarousel({ photos: fullscreenCombinedPhotos });

  const mainPhoto = combinedPhotos.find((ph) => ph.isMain);
  const restPhotos = combinedPhotos.filter((ph) => ph !== mainPhoto);

  const onFullScreenOpen = (index: number) => {
    openFullScreenCarousel(index);
  };

  return combinedPhotos.length === 0 ? (
    <EmptyPlaceholder
      title={t('cards.noUploadedImages')}
      label={t('cards.uploadImageTobuildFaceModel')}
      icon={() => <NoImageSvg />}
    />
  ) : (
    <>
      <Box width={'100%'} height={'calc(100% - 56px)'} overflow={'scroll'}>
        {mainPhoto && (
          <Box
            width={'100%'}
            height={'300px'}
            padding={'16px'}
            borderBottom={`1px solid ${appColors.steal200}`}
          >
            <PhotoWrapper isMain index={0} photo={mainPhoto} onFullScreenOpen={onFullScreenOpen} />
            <Stack
              direction={'row'}
              spacing={1}
              marginTop={'8px'}
              paddingLeft={'8px'}
              alignItems={'center'}
            >
              <Icon icon={icons.IconTaskCompleted} iconColor={UiKitColor.Teal700} />
              <Typography fontSize={'12px'} fontWeight={500}>
                {t('cards.faceModel')}
              </Typography>
            </Stack>
          </Box>
        )}
        <Box className={classes.photoGrid}>
          {restPhotos.map((ph, i) => (
            <PhotoWrapper
              key={i}
              photo={ph}
              index={mainPhoto ? i + 1 : i}
              onFullScreenOpen={onFullScreenOpen}
            />
          ))}
        </Box>
      </Box>
      <CarouselPhotosContextProvider
        onAddMainPhoto={closeFullScreenCarousel}
        onDeletePhoto={closeFullScreenCarousel}
      >
        {fullScreenCarouselRender}
      </CarouselPhotosContextProvider>
    </>
  );
};
