import { appColors } from '@/Providers/ThemeProvider/colors';
import { Box, SvgIcon, SxProps } from '@mui/material';
import React, { useState } from 'react';
import { modelQualityToColor, modelQualityToTitle } from '../../utils/modelQualityUtils';
import { getModelQuality } from '../../utils/getModelQuality';
import { ModelPhotoQualityIcon } from '../../icons/ModelPhotoQuality';
import { FileType } from '@/types/file';
import { PortraitCharacteristicsModal } from '../Media/PortraitCharacteristicsModal';
import { PortraitCharacteristics } from '@/types/model';
import { Tooltip } from 'stc-ui-kit';
import { useTranslation } from 'react-i18next';

interface Props {
  quality: number;
  size?: number;
  type: FileType;
  portraitCharacteristics?: PortraitCharacteristics[];
  wrapperSx?: SxProps;
}

export const ModelQualityIconComponent: React.FC<Props> = ({
  type,
  quality,
  size = 24,
  wrapperSx,
  portraitCharacteristics,
}) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const icon = type === 'audio' ? null : <ModelPhotoQualityIcon />;
  const cursor = type === 'img' ? 'pointer' : 'default';

  const content = (
    <Box
      sx={{
        borderRadius: '50%',
        border: `2px solid ${appColors.white}`,
        background: modelQualityToColor[getModelQuality(quality)],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '4px',
        width: `${size}px`,
        height: `${size}px`,
        cursor: cursor,
        ...wrapperSx,
      }}
    >
      <SvgIcon onClick={() => setIsModalOpen(true)} sx={{ width: '100%' }}>
        {icon}
      </SvgIcon>

      {isModalOpen && portraitCharacteristics?.length ? (
        <PortraitCharacteristicsModal
          onClose={() => setIsModalOpen(false)}
          portraitCharacteristics={portraitCharacteristics}
          quality={quality}
        />
      ) : null}
    </Box>
  );

  return type === 'img' ? (
    <Tooltip tooltip={t(modelQualityToTitle[getModelQuality(quality)], 'Unknown')}>
      {content}
    </Tooltip>
  ) : (
    content
  );
};
