import { Stack, Typography } from '@mui/material';
import { useEffect, useRef } from 'react';
import { useCardCreationContext } from '../../../../hooks/useCardCreationContext';
import { useTranslation } from 'react-i18next';
import { useFullScreenCardCarousel } from '@/modules/multipass/hooks/useFullScreenCardCarousel';
import { CarouselPhotosContextProvider } from '@/modules/multipass/context/CarouselPhotosContext';
import { Button, ButtonType, Icon, icons, Tooltip } from 'stc-ui-kit';
import { InteractiveModePhotoWrapper } from './InteractiveModePhotoWrapper';
import { useDataStore } from '@/Providers/StoreProvider';
import { appColors } from '@/Providers/ThemeProvider/colors';
import { InteractivePhotosSlider } from './InteractivePhotosSlider';
import { makeStyles } from '@/hooks/makeStyles';
import { MAX_MANUAL_MODE_PHOTOS } from '@/modules/multipass/CardCreation/constants';
import { CardPhoto } from '@/types/card';

const PHOTO_SIZE = 240;
const VIDEO_SIZE = 260;
const PLACEHOLDERS_COUNT = 3;

const useStyles = makeStyles()(() => ({
  imageWrapper: {
    '& .actionPanel': {
      transform: 'translateY(110%)',
      transition: 'transform .2s ease',
    },
    '&:hover .actionPanel': {
      transform: 'none',
    },
  },
}));

export function InteractiveModeCamera() {
  const { rootMediaDevicesStore } = useDataStore();
  const { selectedVideoInputDeviceId, getMediaDeviceStream } = rootMediaDevicesStore;

  const { classes } = useStyles();

  const { photosData, deviceRecordingData } = useCardCreationContext();
  const { photos, fullScreenPhotos } = photosData;
  const { takePicture, autoModePhotosRef, manualModePhotoIdsRef } = deviceRecordingData;
  const { t } = useTranslation();
  const videoRef = useRef<HTMLVideoElement>(null);

  const autoModePhotoIds = autoModePhotosRef.current.map((ph) => ph.id);

  const interactiveModePhotosSorter = (a: CardPhoto, b: CardPhoto) => {
    if (a.isMain) return -1;
    if (b.isMain) return 1;

    const isAutoModeA = autoModePhotosRef.current.find((item) => item.id === a.id);
    const isAutoModeB = autoModePhotosRef.current.find((item) => item.id === b.id);

    if (isAutoModeA && !isAutoModeB) return 1;
    if (!isAutoModeA && isAutoModeB) return -1;

    if (isAutoModeA && isAutoModeB) {
      return (
        autoModePhotosRef.current.findIndex((ph) => a.id === ph.id) -
        autoModePhotosRef.current.findIndex((ph) => b.id === ph.id)
      );
    }

    return 0;
  };

  const photosToRender = photos
    /**
     * filter нужен, чтобы при переходе от вкладки "фото" к этой вкладке,
     * фото из вкладки "фото" не отображались, пока идет загрузка фото для этой вкладки
     */
    .filter((ph) => [...manualModePhotoIdsRef.current, ...autoModePhotoIds].includes(ph.id))
    .sort(interactiveModePhotosSorter);

  const {
    carouselRender: fullScreenCarouselRender,
    openFullScreenCarousel,
    closeFullScreenCarousel,
  } = useFullScreenCardCarousel({ photos: fullScreenPhotos.sort(interactiveModePhotosSorter) });

  useEffect(() => {
    if (!selectedVideoInputDeviceId) {
      if (videoRef.current) {
        videoRef.current.srcObject = null;
      }

      return;
    }

    const getVideoSrc = async () => {
      if (!getMediaDeviceStream) return;

      const stream = await getMediaDeviceStream('video', selectedVideoInputDeviceId);
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    };

    void getVideoSrc();
  }, [selectedVideoInputDeviceId, getMediaDeviceStream]);

  return (
    <Stack direction={'row'} gap={4} paddingBlock={3} paddingInline={1} alignItems={'center'}>
      <Stack
        width={VIDEO_SIZE}
        minWidth={VIDEO_SIZE}
        height={VIDEO_SIZE}
        position='relative'
        className={classes.imageWrapper}
        sx={{ border: `4px solid ${appColors.red700}`, overflow: 'hidden' }}
      >
        <video
          style={{
            minWidth: '100%',
            minHeight: '100%',
            objectFit: 'cover',
            objectPosition: 'center',
          }}
          ref={videoRef}
          autoPlay
        />

        <Stack
          justifyContent={'center'}
          alignItems={'center'}
          position={'absolute'}
          bottom={0}
          width={'100%'}
          height={'40px'}
          sx={{
            backgroundColor: appColors.black600,
          }}
          className='actionPanel'
        >
          <Tooltip
            tooltip={
              manualModePhotoIdsRef.current.length >= MAX_MANUAL_MODE_PHOTOS
                ? t('cards.maxPhotoCount', { count: MAX_MANUAL_MODE_PHOTOS })
                : ''
            }
          >
            <Button
              type={ButtonType.Text}
              icon={icons.IconPhoto}
              onClick={takePicture}
              isDisabled={manualModePhotoIdsRef.current.length >= MAX_MANUAL_MODE_PHOTOS}
            >
              {t('cards.takePhoto')}
            </Button>
          </Tooltip>
        </Stack>
      </Stack>

      <InteractivePhotosSlider photoWidth={PHOTO_SIZE}>
        {photosToRender.map((photo, index) => (
          <Stack
            key={index}
            minWidth={PHOTO_SIZE}
            width={PHOTO_SIZE}
            height={PHOTO_SIZE}
            overflow={'hidden'}
          >
            {/* {photo.id} {photo.status} */}
            <InteractiveModePhotoWrapper
              key={index}
              photo={photo}
              index={index}
              onFullScreenOpen={openFullScreenCarousel}
            />
            <CarouselPhotosContextProvider
              onAddMainPhoto={closeFullScreenCarousel}
              onDeletePhoto={closeFullScreenCarousel}
            >
              {fullScreenCarouselRender}
            </CarouselPhotosContextProvider>
          </Stack>
        ))}

        {[...Array(Math.max(PLACEHOLDERS_COUNT - photosToRender.length, 0))].map((_, index) => (
          <Stack
            key={index}
            minWidth={PHOTO_SIZE}
            width={PHOTO_SIZE}
            height={PHOTO_SIZE}
            overflow={'hidden'}
            alignItems={'center'}
            justifyContent={'center'}
            sx={{ background: appColors.black500 }}
            gap={1}
          >
            <Icon icon={icons.IconEyeHidden} />
            <Typography color={appColors.white600} fontWeight={500}>
              {t('cards.photoNotTaken')}
            </Typography>
          </Stack>
        ))}
      </InteractivePhotosSlider>
    </Stack>
  );
}
