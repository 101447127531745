import {
  Button,
  ButtonType,
  ConfirmationType,
  Counter,
  Form,
  Modal,
  Table,
  TableViewMode,
  TextField,
  TextFieldValue,
  Toolbar,
  icons,
  useConfirmationModal,
  useForm,
} from 'stc-ui-kit';
import styles from './Groups.module.scss';
import { useTranslation } from 'react-i18next';
import { IGroup } from '@/types/group';
import { useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import groupService from '@/services/group.service';
import { useGroupsPageContext } from './useGroupsPageContext';
import { useUserPermissionsContext } from '@/hooks/useUserPermissionsContext';
import { permissionControl } from '@/types/permissionControl';

const GROUPS_BLOCK_WIDTH = 320;

interface ITableGroup extends Omit<IGroup, 'id'> {
  id: string; // stc table requires string id
}

const defaultGroup = {
  id: '0',
  groupName: '',
};

export const GroupsBlock = observer(() => {
  const [activeGroup, setActiveGroup] = useState<ITableGroup | null>(null);

  const { t } = useTranslation();
  const { showConfirmationModal } = useConfirmationModal();
  const { havePermissions } = useUserPermissionsContext();
  const { createButton, editButton, deleteButton } = permissionControl.administration.groups;
  const {
    groups,
    isGroupsLoading,
    selectedGroupsIds,
    setSelectedGroupsIds,
    fetchGroups,
    fetchGroupUsers,
  } = useGroupsPageContext();

  const { form: groupNameInput } = useForm<{ groupName: TextFieldValue }>({
    defaultValues: {
      groupName: '',
    },
    values: {
      groupName: activeGroup?.groupName || '',
    },
  });

  const isCreate = activeGroup?.id === '0';

  const selectedGroup: IGroup | undefined = groups.find(
    (g: IGroup) => g.id.toString() === selectedGroupsIds[0]
  );
  const formatGroup = (item: IGroup): ITableGroup => ({ ...item, id: item.id.toString() });

  const columns = [
    {
      id: 'groupName',
      title: 'groupName',
      isVisible: true,
      width: GROUPS_BLOCK_WIDTH,
      renderRowCell: (row: ITableGroup) => row.groupName,
    },
  ];

  const handleEditClick = useCallback(() => {
    if (!selectedGroup) return;

    setActiveGroup(formatGroup(selectedGroup));
  }, [selectedGroup]);

  const handleDeleteClick = useCallback(async () => {
    if (!selectedGroup) return;

    try {
      const submitType = await showConfirmationModal({
        title: t('groups.deleteGroup'),
        text: t('groups.confirmDelete', { name: selectedGroup?.groupName }),
        note: t('groups.confirmDeleteNote'),
        primaryButtonText: t('delete'),
        cancelButtonText: t('cancel'),
      });

      if (submitType === ConfirmationType.PrimaryAction) {
        await groupService.delete(selectedGroup.id);
        await fetchGroups();
        setSelectedGroupsIds(groups.length ? [groups[0].id.toString()] : []);
      }
    } catch (error) {
      console.error(error);
    }
  }, [fetchGroups, groups, selectedGroup, setSelectedGroupsIds, showConfirmationModal, t]);

  const handleConfirm = useCallback(async () => {
    if (!activeGroup) return;

    if (isCreate) {
      try {
        const newGroup = await groupService.create({
          id: Number(activeGroup.id),
          groupName: groupNameInput.watch().groupName || '',
        });

        if (newGroup) {
          setSelectedGroupsIds([newGroup.id.toString()]);
          await fetchGroups();
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      try {
        await groupService.edit({
          id: Number(activeGroup.id),
          groupName: groupNameInput.watch().groupName || '',
        });

        fetchGroupUsers();
        await fetchGroups();
      } catch (error) {
        console.error(error);
      }
    }

    setActiveGroup(null);
    groupNameInput.reset();
  }, [activeGroup, fetchGroups, fetchGroupUsers, groupNameInput, setSelectedGroupsIds, isCreate]);

  return (
    <div className={styles.groupsBlock}>
      <Toolbar className={styles.toolBar} data-testid=''>
        <div className={styles.counter}>
          <span>{t('groups.groups')}</span>
          <Counter count={groups?.length || 0} data-testid='' />
        </div>

        <div className={styles.actions}>
          {havePermissions(createButton) && (
            <Button
              icon={icons.IconAdd}
              type={ButtonType.Icon}
              tooltip={t('create')}
              isActive
              onClick={() => setActiveGroup(defaultGroup)}
            />
          )}
          {havePermissions(editButton) && (
            <Button
              icon={icons.IconEdit}
              type={ButtonType.Icon}
              tooltip={t('edit')}
              onClick={handleEditClick}
            />
          )}
          {havePermissions(deleteButton) && (
            <Button
              icon={icons.IconDelete}
              type={ButtonType.Icon}
              tooltip={t('delete')}
              onClick={handleDeleteClick}
            />
          )}
        </div>
      </Toolbar>

      <Table
        items={groups.map((g: IGroup) => formatGroup(g))}
        columns={columns}
        isItemsLoading={isGroupsLoading}
        onScroll={() => new Promise((resolve) => resolve())}
        onColumnResize={() => null}
        pagination={{
          isDisabled: true,
          paging: {
            page: 1,
            pageSize: 10,
          },
          onPageChange: () => null,
          onPageSizeChange: () => null,
        }}
        viewMode={TableViewMode.Table}
        onSelect={setSelectedGroupsIds}
        selectedItemsIds={selectedGroupsIds}
        isPaddingDisabled
        isSingleSelection
        data-testid=''
      />

      {!!activeGroup && (
        <Modal
          onClose={() => setActiveGroup(null)}
          onConfirm={handleConfirm}
          data-testid=''
          title={t(isCreate ? 'groups.createGroup' : 'groups.editGroup')}
          buttonConfirmText={t(isCreate ? 'create' : 'save')}
          isConfirmButtonHidden={
            isCreate
              ? !havePermissions(permissionControl.administration.groups.createConfirmButton)
              : !havePermissions(permissionControl.administration.groups.editConfirmButton)
          }
          buttonCloseText={t('cancel')}
          isConfirmDisabled={!groupNameInput.watch().groupName || !groupNameInput.formState.isDirty}
        >
          <Form form={groupNameInput} data-testid=''>
            <TextField
              id='groupName'
              label={t('groups.groupName')}
              data-testid=''
              isRequired
              placeholder={t('groups.enterGroupName')}
              isFocusOnLoad
            />
          </Form>
        </Modal>
      )}
    </div>
  );
});
