import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Checkbox,
  Typography,
  Stack,
} from '@mui/material';
import { Button, ButtonStyle, ButtonDropdown, icons } from 'stc-ui-kit';
import { useState, Fragment, useCallback } from 'react';
import Directory from './Directory';
import { CollapsableSection } from './CollapsableSection';
import { useTranslation } from 'react-i18next';
import { BreadCrumbsSection } from './BreadCrumbsSection';
import { useFileDialog } from './hooks/useFileDialog';
import { IStorageFile } from './types';
import { FileQueue } from '../FileQueue';
import filesService from '@/services/files.service';
import { observer } from 'mobx-react';
import { useDataStore } from '@/Providers/StoreProvider';

interface IFileDialog {
  isDisabled?: boolean;
  onFileChoosen: (params: {
    selectedFiles: IStorageFile[];
    prepareFiles: boolean;
    dontUseForPersonsEntries: boolean;
  }) => void;
}

export const FileDialog = observer((props: IFileDialog) => {
  const { onFileChoosen, isDisabled } = props;
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const { constantsStore } = useDataStore();
  const {
    loadFromExternal,
    audioProcessingType,
    initConstLoadFromExternal,
    initConstAudioProcessingType,
  } = constantsStore;

  const {
    currentPath,
    items,
    onFileClick,
    selectedFiles,
    isCollapseDirectory,
    opnCloseDirectory,
    loading,
    loadDirectory,
    resetState,
  } = useFileDialog();

  const [needPrepareFiles, setNeedPrepareFiles] = useState(true);
  const [dontUseForPersonsEntries, setDontUseForPersonsEntries] = useState(false);

  const handleClickOpen = async () => {
    setOpen(true);
    if (!audioProcessingType) {
      void initConstAudioProcessingType();
    }

    let isLoadFromFolder;
    if (loadFromExternal === undefined) {
      isLoadFromFolder = await initConstLoadFromExternal();
    }

    if (isLoadFromFolder || loadFromExternal) {
      await loadDirectory(currentPath);
    }
  };

  const handleClose = useCallback(() => {
    setOpen(false);
    resetState();
  }, [resetState]);

  const handleOpenFile = useCallback(async () => {
    if (selectedFiles.length === 0) return;

    await filesService.addFile({
      processingParametersByFileType: {
        audio: [
          {
            type: 'HardcoreEnroll',
            data: {
              sliceOnly: needPrepareFiles,
              buildModels: !dontUseForPersonsEntries,
            },
          },
        ],
      },
      paths: selectedFiles.map((f) => ({
        path: f.path,
        type: f.type,
      })),
    });

    onFileChoosen({
      selectedFiles,
      prepareFiles: needPrepareFiles,
      dontUseForPersonsEntries,
    });

    handleClose();
  }, [dontUseForPersonsEntries, handleClose, needPrepareFiles, onFileChoosen, selectedFiles]);

  const dialogTextStyle: React.CSSProperties = {
    fontSize: '14px',
    color: 'black',
  };

  return (
    <Fragment>
      <ButtonDropdown
        data-testid=''
        onMainButtonClick={handleClickOpen}
        icon={icons.IconAdd}
        buttonStyle={ButtonStyle.Primary}
        label={t('add')}
        isCloseOnClick={false}
        content={<FileQueue />}
        isPortal
        placement='bottom'
      />

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        PaperProps={{
          sx: {
            width: '100%',
            height: '100%',
            maxWidth: '55.8vw',
            maxHeight: '55.8vh',
            minWidth: '32vw',
          },
        }}
      >
        <DialogTitle id='alert-dialog-title' sx={{ background: 'rgba(190, 206, 218, 0.24)' }}>
          {t('FileDialog.title')}
        </DialogTitle>
        <Divider variant='fullWidth' />

        <DialogContent sx={{ paddingLeft: '8px' }}>
          {loadFromExternal && (
            <>
              <BreadCrumbsSection currentPath={currentPath} onDirectoryClick={loadDirectory} />
              <Divider variant='fullWidth' />

              <CollapsableSection
                title={t('FileDialog.folderSection')}
                count={items.filter((i) => i.type === 'DIR').length}
                isCollapse={isCollapseDirectory}
                openClose={opnCloseDirectory}
              >
                <Directory
                  loading={loading}
                  selectedItems={selectedFiles}
                  items={items}
                  onFileClick={onFileClick}
                  onDirectoryDoubleClick={loadDirectory}
                />
              </CollapsableSection>
              {/* <CollapsableSection
            title={t('FileDialog.deviceSection')}
            count={0}
            isCollapse={isCollapseIDevices}
            openClose={opnCloseDevices}
          ></CollapsableSection> */}
            </>
          )}
          {!loadFromExternal && <Typography>Нет доступа к сетевой папке</Typography>}
        </DialogContent>
        <DialogActions sx={{ background: 'rgba(190, 206, 218, 0.24)', padding: 0 }}>
          <Stack
            direction={'row'}
            width={'100%'}
            justifyContent={'space-between'}
            gap='16px'
            padding='16px'
          >
            <Stack direction={'row'} gap='5px'>
              {audioProcessingType === 'HardcoreEnroll' && (
                <>
                  <Stack direction={'row'} alignItems={'center'} component='label'>
                    <Checkbox
                      checked={dontUseForPersonsEntries}
                      onChange={(v) => setDontUseForPersonsEntries(v.target.checked)}
                      color='default'
                    />
                    <Typography sx={dialogTextStyle}>
                      {t('FileDialog.dontUseForPersonCreation')}
                    </Typography>
                  </Stack>

                  <Stack direction={'row'} alignItems={'center'} component='label'>
                    <Checkbox
                      checked={needPrepareFiles}
                      onChange={(v) => setNeedPrepareFiles(v.target.checked)}
                      color='default'
                    />
                    <Typography sx={dialogTextStyle}>
                      {t('FileDialog.prepareFilesForUploading')}
                    </Typography>
                  </Stack>
                </>
              )}
            </Stack>
            <Stack direction={'row'} gap={2}>
              <Button
                isDisabled={
                  selectedFiles.length === 0 || (!needPrepareFiles && dontUseForPersonsEntries)
                }
                onClick={handleOpenFile}
              >
                {t('add')}
              </Button>
              <Button buttonStyle={ButtonStyle.Secondary} onClick={handleClose}>
                {t('FileDialog.cancel')}
              </Button>
            </Stack>
          </Stack>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
});
