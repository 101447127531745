import { Box } from '@mui/material';
import {
  Counter,
  Form,
  useForm,
  SearchField,
  icons,
  Separator,
  Button,
  ButtonStyle,
  QuickFilterItem,
  RadioGroupFieldValue,
  QuickFilterTypes,
  QuickFilters,
} from 'stc-ui-kit';
import { SearchFieldValue } from 'stc-ui-kit/components/fields/SearchField/SearchField';
import styles from './FileQueue.module.scss';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo } from 'react';
import { EFileProcessStatus, IFileLink } from '@/types/file';
import filesService from '@/services/files.service';
import { useGridStore } from '@/hooks/grid/useGridStore';
import { DataGridStorePredicates, EDataGridStoreSortMode, IGridColumnFilter } from '@/types/grid';
import { observer } from 'mobx-react';
import { FileQueueTable } from './FileQueueTable';

const DEFAULT_CONTAINER_SIZE = { width: 582, height: 620 };

export const FileQueue = observer(function FileQueue() {
  const { t } = useTranslation();

  const { store } = useGridStore<IFileLink>({
    dataProvider: (state) => filesService.dataProvider(state),
    defaultSortState: {
      sort: EDataGridStoreSortMode.DSC,
      sortField: 'createdTime',
    },
    withInfiniteScroll: true,
    initialState: {
      limit: 50,
    },
  });

  useEffect(() => {
    store.applyFilters();
  }, [store, store.state.columnFilterMap]); //? maybe [store.state.columnFilterMap]

  const hasContent = store.data.length || store.state.columnFilterMap.length;

  const statusFilterItems: QuickFilterItem<{ status: RadioGroupFieldValue }>[] = useMemo(
    () => [
      {
        id: 'all',
        name: t('all'),
        type: QuickFilterTypes.RadioGroup,
        fieldId: 'status',
      },
      {
        id: EFileProcessStatus.NEW,
        name: t('multipass.fileProcessStatus.NEW'),
        type: QuickFilterTypes.RadioGroup,
        fieldId: 'status',
      },
      {
        id: EFileProcessStatus.INPROGRESS,
        name: t('multipass.fileProcessStatus.IN_PROGRESS'),
        type: QuickFilterTypes.RadioGroup,
        fieldId: 'status',
      },
      {
        id: EFileProcessStatus.COMPLETED,
        name: t('multipass.fileProcessStatus.COMPLETED'),
        type: QuickFilterTypes.RadioGroup,
        fieldId: 'status',
      },
      {
        id: EFileProcessStatus.DUPLICATE,
        name: t('multipass.fileProcessStatus.DUPLICATE'),
        type: QuickFilterTypes.RadioGroup,
        fieldId: 'status',
      },
      {
        id: EFileProcessStatus.FAILED,
        name: t('multipass.fileProcessStatus.FAILED'),
        type: QuickFilterTypes.RadioGroup,
        fieldId: 'status',
      },
    ],
    [t]
  );

  const { form: searchFieldForm } = useForm<{ name: SearchFieldValue }>({
    defaultValues: {
      name: '',
    },
  });

  const onSearchChange = () => {
    const filterIndex = store.state.columnFilterMap.findIndex((f) => f.key === 'filename');
    const updatedFilters = [...store.state.columnFilterMap];
    const arrayIndex = filterIndex === -1 ? updatedFilters.length : filterIndex;

    updatedFilters[arrayIndex] = {
      key: 'filename',
      predicate: DataGridStorePredicates.Contains,
      targetValues: [searchFieldForm.watch().name || ''],
    };

    store.setState({ ...store.state, columnFilterMap: updatedFilters });
  };

  const formatStoreFilters = (
    storeFilters: IGridColumnFilter<IFileLink>[],
    filterComponentItems: QuickFilterItem<{ status: RadioGroupFieldValue }>[]
  ): QuickFilterItem<{ status: RadioGroupFieldValue }>[] => {
    const result: QuickFilterItem<{ status: RadioGroupFieldValue }>[] = [];

    storeFilters.forEach((sf) => {
      if (sf.key === 'status' && sf.targetValues) {
        sf.targetValues.forEach((tv) => {
          const filterItem = filterComponentItems.find((item) => item.id === tv);
          if (filterItem) {
            result.push(filterItem);
          }
        });
      }
    });

    if (result.length === 0) {
      const allFilterItem = filterComponentItems.find((item) => item.id === 'all');
      if (allFilterItem) {
        result.push(allFilterItem);
      }
    }

    return result;
  };

  const filtersComponentValue = useMemo(() => {
    return formatStoreFilters(store.state.columnFilterMap, statusFilterItems);
  }, [store.state.columnFilterMap, statusFilterItems]);

  const onFilterChange = (selectedFilter: QuickFilterItem<{ status: RadioGroupFieldValue }>) => {
    const updatedFilters = [...store.state.columnFilterMap];
    const existingFilterIndex = updatedFilters.findIndex((f) => f.key === 'status');

    if (existingFilterIndex !== -1) {
      updatedFilters.splice(existingFilterIndex, 1);
    }

    if (selectedFilter.id !== 'all') {
      updatedFilters.push({
        key: 'status',
        predicate: DataGridStorePredicates.Equals,
        targetValues: [selectedFilter.id],
      });
    }

    store.setState({
      ...store.state,
      columnFilterMap: updatedFilters,
    });
  };

  return (
    <Box
      className={styles.fileQueue}
      style={{
        width: DEFAULT_CONTAINER_SIZE.width,
        height: DEFAULT_CONTAINER_SIZE.height,
      }}
    >
      <Box className={styles.toolBar}>
        <Box className={styles.searchWrapper}>
          <Box className={styles.counter}>
            <span>{t('tasks')}</span>
            <Counter count={store.data.length} data-testid='' />
          </Box>

          {!!hasContent && (
            <Form className={styles.searchField} form={searchFieldForm} data-testid=''>
              <SearchField
                id='name'
                placeholder={t('searchByFileName')}
                data-testid=''
                debouncedOnChange={onSearchChange}
              />
            </Form>
          )}
        </Box>

        {!!hasContent && (
          <Box className={styles.quickFilter}>
            <QuickFilters
              value={filtersComponentValue}
              onChange={onFilterChange}
              items={statusFilterItems}
            />
          </Box>
        )}
      </Box>

      <Separator horizontal />

      <FileQueueTable store={store} />

      <Separator horizontal />
      <Box className={styles.footer}>
        <Button
          icon={icons.IconDelete}
          buttonStyle={ButtonStyle.Secondary}
          // onClick={async () => {
          //   await filesService.deleteFinishedFileProcesses();
          //   await store.reload();
          // }}
        >
          {t('multipass.deleteCompletedProcesses')}
        </Button>
      </Box>
    </Box>
  );
});
