import { ICard } from '@/types/card';
import { IFileLink } from '@/types/file';

export enum SseMessageType {
  Ping = 'Ping',
  CardUpdated = 'CardUpdated',
  CardFileCreated = 'CardFileCreated',
  CardFileUpdated = 'CardFileUpdated',
  CardFilesDeleted = 'CardFilesDeleted',
  CardFoundInHotlist = 'CardFoundInHotlist',
  GUID = 'GUID',
}

export type SseMessage =
  | PingSseMessage
  | CardSseMessage
  | CardFileSseMessage
  | CardFoundInHotlistSseMessage
  | GuidSseMessage;

type PingSseMessage = {
  type: SseMessageType.Ping;
};

export type CardFileSseMessage = {
  cardId: number;
} & (
  | {
      type: SseMessageType.CardFileCreated | SseMessageType.CardFileUpdated;
      fileView: IFileLink;
      fileLinkIds: never;
    }
  | {
      type: SseMessageType.CardFilesDeleted;
      fileView: never;
      fileLinkIds: number[];
    }
);

export type CardSseMessage = {
  type: SseMessageType.CardUpdated;
  card: ICard;
  fromUserDevice: boolean;
};

export type CardFoundInHotlistSseMessage = {
  type: SseMessageType.CardFoundInHotlist;
  cardId: number;
  cardAlias: string;
};

export type GuidSseMessage = {
  type: SseMessageType.GUID;
  guid: string;
};
