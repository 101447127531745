import { Box, Stack, Typography } from '@mui/material';
import { ReactComponent as ZoomImageIcon } from 'assets/icons/zoomImage.svg';
import { SvgIcon as CustomSvgIcon } from 'components/SvgIcon/SvgIcon';
import { appColors } from '@/Providers/ThemeProvider/colors';
import { ModelQualityIconComponent } from '../icons/ModelQualityIconComponent';
import { StyledCarousel } from '@/components/Carousel/StyledCarousel';
import { useFullScreenCardCarousel } from '../../hooks/useFullScreenCardCarousel';
import { CarouselPhotosContextProvider } from '../../context/CarouselPhotosContext';
import { Button, ButtonType, Icon, UiKitColor, icons } from 'stc-ui-kit';
import { makeStyles } from '@/hooks/makeStyles';
import { useTranslation } from 'react-i18next';
import { useCardCreationContext } from '../../CardCreation/hooks/useCardCreationContext';
import { Loader } from '@/components/common/Loader';
import { useRef } from 'react';
import Slider from 'react-slick';
import { ReactComponent as PhotoPlaceholder } from 'assets/icons/carousel/photoPlaceholder.svg';
import { useImageErrorPlaceholder } from '@/hooks/useImageErrorPlaceholder';

const useStyles = makeStyles()(() => ({
  zoomImageBtn: {
    position: 'absolute',
    bottom: '10px',
    right: '10px',
    zIndex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '6px',
  },
}));

export const PhotosCarousel = ({ withLoader = false }: { withLoader?: boolean }) => {
  const { cardData, photosData } = useCardCreationContext();
  const { fullscreenCombinedPhotos, combinedPhotos, isPhotosLoading } = photosData;
  const { classes } = useStyles();
  const { t } = useTranslation();
  const sliderRef = useRef<Slider>(null);
  const { onError } = useImageErrorPlaceholder();

  const {
    carouselRender: fullScreenCarouselRender,
    closeFullScreenCarousel,
    openFullScreenCarousel,
  } = useFullScreenCardCarousel({ photos: fullscreenCombinedPhotos });

  const onFullScreenOpen = (index: number) => {
    openFullScreenCarousel(index);
  };

  return (
    <>
      {!!combinedPhotos.length && (
        <>
          <Box width='100%' height='100%' sx={{ backgroundColor: appColors.white }}>
            <StyledCarousel sliderRef={sliderRef}>
              {combinedPhotos.map((ph, i) => {
                const mainPhotoFaceData = ph.isMain
                  ? ph.faceData?.find((d) => d.modelId === cardData?.faceModel?.id)
                  : null;

                return (
                  <Box
                    key={ph.path.concat(String(i))}
                    width='100%'
                    height='100%'
                    display='flex'
                    alignItems='center'
                    position='relative'
                    sx={{ background: appColors.black }}
                  >
                    <Box
                      position='absolute'
                      top='0'
                      padding='16px'
                      display='flex'
                      justifyContent={i === 0 ? 'space-between' : 'flex-end'}
                      alignItems='center'
                      width='100%'
                      zIndex='10'
                    >
                      {ph.isMain && (
                        <Stack direction={'row'} spacing={1} alignItems={'center'}>
                          <Icon icon={icons.IconTaskCompleted} iconColor={UiKitColor.Teal700} />
                          <Typography fontSize={'12px'} fontWeight={500} color={appColors.black600}>
                            {t('cards.faceModel')}
                          </Typography>
                        </Stack>
                      )}

                      {ph.faceData.length === 1 ? (
                        <Box display='flex' alignItems='center' gap='16px'>
                          <ModelQualityIconComponent
                            quality={ph.faceData[0]?.quality}
                            type='img'
                            portraitCharacteristics={ph.faceData[0]?.portraitCharacteristics}
                          />
                        </Box>
                      ) : (
                        mainPhotoFaceData && (
                          <ModelQualityIconComponent
                            quality={mainPhotoFaceData.quality}
                            type='img'
                            portraitCharacteristics={mainPhotoFaceData.portraitCharacteristics}
                          />
                        )
                      )}
                    </Box>

                    <img
                      src={ph.path}
                      alt=''
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain',
                      }}
                      onError={onError}
                    />

                    <Button
                      icon={() => (
                        <CustomSvgIcon IconComponent={ZoomImageIcon} color={appColors.grey800} />
                      )}
                      onClick={() => onFullScreenOpen(i)}
                      className={classes.zoomImageBtn}
                      type={ButtonType.Icon}
                    />
                  </Box>
                );
              })}
            </StyledCarousel>
          </Box>
          <CarouselPhotosContextProvider
            onAddMainPhoto={closeFullScreenCarousel}
            onDeletePhoto={closeFullScreenCarousel}
          >
            {fullScreenCarouselRender}
          </CarouselPhotosContextProvider>
        </>
      )}

      {!combinedPhotos.length && <PhotoPlaceholder />}

      {isPhotosLoading && withLoader && (
        <Box
          width={'100%'}
          height={'100%'}
          alignItems={'center'}
          display={'flex'}
          justifyContent={'center'}
          sx={{ backgroundColor: appColors.steal200 }}
          position={'absolute'}
        >
          <Loader text='' isOpaque />
        </Box>
      )}
    </>
  );
};
