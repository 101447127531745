import { useDataStore } from '@/Providers/StoreProvider';
import { appColors } from '@/Providers/ThemeProvider/colors';
import { Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { icons, Link, showNotification, UiKitColor } from 'stc-ui-kit';
import { createCardEditLink } from './utils/cardCreationLinks';
import { CopyIdButton } from '@/components/common/ui/CopyIdButton';

/**
 * эта логика сделана компонентом, а не хуком, чтобы не оборачивать в observer родительский компонент
 */
export const HotlistSseMessageObserver = observer(function HotlistSseMessageObserver() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { sseStore } = useDataStore();

  useEffect(() => {
    const message = sseStore.cardFoundInHotlistMessage;

    if (!message) return;

    showNotification({
      autoCloseTimeoutSeconds: 0,
      content: (
        <>
          <Typography fontSize={'14px'} fontWeight={500} color={appColors.black}>
            {t('messages.cardFoundInHotlist', {
              name: message.cardAlias,
            })}
          </Typography>

          <Link
            onClick={() =>
              navigate(
                createCardEditLink({
                  id: message.cardId,
                  from: window.location.pathname + window.location.search,
                })
              )
            }
            data-testid=''
          >
            {t('messages.openCard')}
          </Link>

          <CopyIdButton id={message.cardId} isCompact={false} />
        </>
      ),
      icon: {
        icon: icons.IconTaskHotControl,
        color: UiKitColor.Red900,
      },
      dataTestId: '',
    });

    sseStore.setCardFoundInHotlistMessage(null);
  }, [sseStore.cardFoundInHotlistMessage, sseStore, navigate, t]);

  return <Fragment />;
});
