import { appColors } from '@/Providers/ThemeProvider/colors';
import { ModelQuality } from '@/types/dictor';
import { EPortraitCharacteristicsQuality } from '@/types/model';
import { ReactComponent as goodQualityIcon } from '@/assets/icons/characteristicQualityGood.svg';
import { ReactComponent as poorQualityIcon } from '@/assets/icons/characteristicQualityPoor.svg';
import { ReactComponent as undefinedQualityIcon } from '@/assets/icons/characteristicQualityUndefined.svg';

export const modelQualityToColor: Record<ModelQuality, string> = {
  GOOD: appColors.green900,
  NORMAL: appColors.orange900,
  BAD: appColors.red900,
};

export const modelQualityToTitle: Record<ModelQuality, string> = {
  GOOD: 'multipass.excellentQuality',
  NORMAL: 'multipass.goodQuality',
  BAD: 'multipass.badQuality',
};

export const getIconByCharacteristicQuality = (quality: EPortraitCharacteristicsQuality) => {
  switch (quality) {
    case EPortraitCharacteristicsQuality.HIGH:
      return goodQualityIcon;
    case EPortraitCharacteristicsQuality.LOW:
      return poorQualityIcon;
    default:
      return undefinedQualityIcon;
  }
};
