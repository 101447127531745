import { useDataStore } from '@/Providers/StoreProvider';
import { GridStore } from '@/stores/grid.store';
import { ICardsGridItem } from '@/types/card';
import { SseMessageType } from '@/types/sse';
import { useEffect } from 'react';

export const useCardsGridSseMessages = ({
  cardsGridStore,
}: {
  cardsGridStore: GridStore<ICardsGridItem>;
}) => {
  const { sseStore } = useDataStore();
  const { cardMessage } = sseStore;

  useEffect(() => {
    if (!cardMessage) return;

    switch (cardMessage.type) {
      case SseMessageType.CardUpdated:
        const updatedData = [...cardsGridStore.data];
        const updatedCard = cardMessage.card;
        const targetIndex = updatedData.findIndex((card) => card.id === updatedCard.id);
        updatedData[targetIndex] = {
          ...updatedData[targetIndex],
          ...updatedCard,
          editorName: updatedCard.editor?.username || updatedData[targetIndex].editorName,
          voicePath: updatedCard.audioPath,
        };

        cardsGridStore.setData({ data: updatedData, hasNextPage: cardsGridStore.hasNextPage });

        break;

      default:
        break;
    }
  }, [cardMessage, cardsGridStore]);

  useEffect(() => {
    if (sseStore.isReconnectedAfterError) {
      sseStore.setIsReconnectedAfterError(false);
      void cardsGridStore.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sseStore.isReconnectedAfterError]);
};
