import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ButtonType,
  ConfirmationType,
  Tooltip,
  icons,
  useConfirmationModal,
} from 'stc-ui-kit';
import { useSnackBarMessage } from '@/hooks/useSnackBarMessage';
import { cardsService } from '@/services/cards.service';
import { GridStore } from '@/stores/grid.store';
import { ICardsGridItem } from '@/types/card';
import React from 'react';
import { showInfoNotification } from '@/utils/notifications';

interface Props {
  store: GridStore<ICardsGridItem>;
}

export const DeleteCardsButton: React.FC<Props> = observer(({ store }) => {
  const { t } = useTranslation();

  const { showConfirmationModal } = useConfirmationModal();
  const { error } = useSnackBarMessage();

  const selectedCards = store.checkedItems;
  const selectedCount = store.checkedItems.length;

  const onCardsDelete = async () => {
    try {
      const confirmationType = await showConfirmationModal({
        title: t('cards.deleteCard'),
        text:
          selectedCards.length > 1
            ? t('cards.areYouShureDirDeleteOneSeveral', { count: selectedCards.length })
            : t('cards.areYouShureDirDeleteOne'),
        primaryButtonText: t('delete'),
        cancelButtonText: t('cancel'),
      });

      if (confirmationType !== ConfirmationType.PrimaryAction) return;

      const { errorDescription, res } = await cardsService.deleteCards(
        selectedCards.map((card) => card.id)
      );

      if (errorDescription) {
        error(errorDescription.description);

        return;
      }

      if (res && res.length > 0) {
        res.forEach((entry) => {
          error(entry?.error?.description);
        });
      }

      await store.reload();
      store.resetChecked();

      showInfoNotification({ header: t('messages.cardsDeleted') });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Tooltip tooltip={t('delete')}>
      <Button
        type={ButtonType.Icon}
        icon={icons.IconDelete}
        onClick={onCardsDelete}
        isDisabled={selectedCount === 0}
      />
    </Tooltip>
  );
});
