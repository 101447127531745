import React from 'react';
import { useCardCreationContext } from '../hooks/useCardCreationContext';
import { Box, Paper, Typography, Stack } from '@mui/material';
import { appColors } from '@/Providers/ThemeProvider/colors';
import { useTranslation } from 'react-i18next';
import { PhotosCarousel } from '../../components';
import { Separator, Tooltip } from 'stc-ui-kit';
import { FaceData } from '@/types/model';
import CardModelsAvatars from '@/components/FileDialog/CardModelsAvatars';
import { formatDateTimeString } from '@/utils/formatDateTimeString';
import { StatusWithIcon } from '../../StatusWithIcon';
import { getCardAliasWithCreationDate } from '@/utils/getCardAliasWithCreationDate';

export const CardDataSection = () => {
  const { t } = useTranslation();
  const { cardData, selectedTemplate } = useCardCreationContext();

  const photoNormalizedPath = (cardData?.faceModel?.data as FaceData)?.files.find(
    (f) => f.type === 'FACE_NORMALIZED'
  )?.data.uri;

  const personData: {
    title: string;
    value: string;
    valueRenderer?: (value: string) => React.ReactNode;
  }[] = [
    {
      title: t('cards.biometricModels'),
      value: '',
      valueRenderer: () => {
        return (
          <CardModelsAvatars
            photoPath={photoNormalizedPath || ''}
            voiceModelQuality={cardData?.voiceModel?.quality}
            faceModelQuality={cardData?.faceModel?.quality}
          />
        );
      },
    },
    {
      title: t('cards.created'),
      value: formatDateTimeString(cardData?.createdOnDateTime),
      valueRenderer: () => formatDateTimeString(cardData?.createdOnDateTime),
    },
    {
      title: t('cards.createdBy'),
      value: cardData?.creator.username || '',
    },
    {
      title: t('cards.changed'),
      value: formatDateTimeString(cardData?.editedOnDateTime),
      valueRenderer: () => formatDateTimeString(cardData?.editedOnDateTime),
    },
    {
      title: t('cards.changedBy'),
      value: cardData?.editor?.username || '',
    },
    {
      title: t('cards.source'),
      value: cardData?.sourceFiles?.join(', ') || '',
    },
    {
      title: t('multipass.status'),
      value: t(`cards.exportStatus.${cardData?.exportStatus || ''}`, 'cards.exportStatus.unknown'),
      valueRenderer: () => {
        return cardData ? <StatusWithIcon type={'card'} status={cardData.exportStatus} /> : '';
      },
    },
  ];

  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: '1 408px',
        maxWidth: '408px',
        background: appColors.darkThemeBackground,
        marginRight: '16px',
        border: `1px solid ${appColors.darkThemeBorder}`,
      }}
    >
      <Box display='flex' alignItems='center' padding='16px'>
        <Typography
          variant='subtitle2'
          textOverflow={'ellipsis'}
          overflow={'hidden'}
          whiteSpace={'nowrap'}
        >
          {getCardAliasWithCreationDate(cardData, selectedTemplate)}
        </Typography>
      </Box>

      <Separator horizontal />

      <Stack
        direction={'column'}
        sx={{
          flex: '1',
          overflowY: 'auto',
        }}
      >
        <Stack
          direction='column'
          alignItems='center'
          justifyContent='center'
          sx={{ height: '376px', margin: '16px', backgroundColor: appColors.grey }}
        >
          <PhotosCarousel />
        </Stack>

        <Box display='flex' alignItems='center' height={'48px'} padding={2}>
          <Typography fontSize={16} fontWeight={700}>
            {t('multipass.personalData')}
          </Typography>
        </Box>

        <Separator horizontal />

        <Stack padding='16px' paddingRight='46px' direction='column' spacing='16px'>
          {personData.map((d, i) => (
            <Stack key={i} display='flex' direction='row' alignItems='center'>
              <Typography
                color={appColors.white600}
                flex='0 0 150px'
                marginRight='8px'
                fontSize='12px'
                fontWeight='700'
              >
                {d.title}:
              </Typography>
              <Tooltip tooltip={d.value}>
                <Box
                  display='flex'
                  alignItems='center'
                  fontWeight='500'
                  fontSize='14px'
                  overflow='hidden'
                >
                  <Box
                    sx={{
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {d.valueRenderer?.(d.value) || d.value}
                  </Box>
                </Box>
              </Tooltip>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Paper>
  );
};
