import { StyledCarousel } from '@/components/Carousel/StyledCarousel';
import { Box, Dialog } from '@mui/material';
import { ReactComponent as CloseIcon } from 'assets/icons/zoomOutImage.svg';
import { SvgIcon as CustomSvgIcon } from 'components/SvgIcon/SvgIcon';
import { ImageWithFaceModels } from './ImageWithFaceModels';
import { CardPhoto } from '@/types/card';
import { Loader } from '@/components/common/Loader';
import { makeStyles } from '@/hooks/makeStyles';
import Slider from 'react-slick';
import { Button, ButtonType, icons, Tooltip } from 'stc-ui-kit';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { appColors } from '@/Providers/ThemeProvider/colors';

interface Props {
  handleClose: () => void;
  photos: CardPhoto[];
  isLoading?: boolean;
  slideIndex: number;
}

const useStyles = makeStyles()(() => ({
  zoomOutBtn: {
    position: 'absolute',
    bottom: '10px',
    right: '10px',
    color: 'white',
  },
  closeBtn: {
    position: 'absolute',
    top: '16px',
    right: '16px',
    color: 'white',
  },
  arrowLeftBtn: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%) scale(1.5)',
    left: '24px',
  },
  arrowRightBtn: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%) scale(1.5)',
    right: '24px',
  },
}));

export const FullScreenPhotoCarousel: React.FC<Props> = ({
  handleClose,
  photos,
  isLoading = false,
  slideIndex,
}) => {
  const { classes } = useStyles();
  const sliderRef = useRef<Slider>(null);
  const { t } = useTranslation();
  const [stableOrderPhotos, setStableOrderPhotos] = useState(photos);

  // это нужно, чтобы порядок фото сохранялся прежним при выборе новой ЛМ
  useEffect(() => {
    setStableOrderPhotos((prev) => {
      if (photos.length === prev.length) {
        const result: CardPhoto[] = [];
        photos.forEach((ph) => {
          const index = prev.findIndex((pr) => pr.id === ph.id);
          if (index > -1) {
            result[index] = ph;
          }
        });

        return result;
      } else {
        return photos;
      }
    });
  }, [photos]);

  return (
    <Dialog
      fullWidth
      maxWidth='xl'
      open={true}
      onClose={handleClose}
      TransitionProps={{
        style: {
          padding: '60px',
        },
      }}
      PaperProps={{
        style: {
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          maxWidth: 'none',
          maxHeight: 'none',
          margin: 0,
          background: 'black',
        },
      }}
    >
      <Box
        width='100%'
        height='100%'
        sx={{ backgroundColor: appColors.black, position: 'relative' }}
      >
        {isLoading ? (
          <Box
            width='100%'
            height='100%'
            display='flex'
            alignItems='center'
            justifyContent='center'
          >
            <Loader text='' />
          </Box>
        ) : (
          <StyledCarousel sliderRef={sliderRef} sliderProps={{ initialSlide: slideIndex }}>
            {stableOrderPhotos.map((ph, i) => (
              <Box
                key={ph.path.concat(String(i))}
                width='100%'
                height='100%'
                display='flex !important'
                alignItems='center'
                justifyContent={'center'}
                position='relative'
                paddingInline={'56px'}
                paddingBottom={'56px'}
                sx={{
                  svg: {
                    fill: '#fff !important',
                  },
                }}
              >
                <ImageWithFaceModels photo={ph} onCarouselClose={handleClose} />

                <Tooltip tooltip={t('multipass.closeFullscreen')} className={classes.zoomOutBtn}>
                  <Button
                    onClick={handleClose}
                    type={ButtonType.Icon}
                    icon={() => (
                      <CustomSvgIcon
                        IconComponent={CloseIcon}
                        color='white'
                        size={{ w: 24, h: 24 }}
                      />
                    )}
                  />
                </Tooltip>

                <Tooltip tooltip={t('close')} className={classes.closeBtn}>
                  <Button onClick={handleClose} type={ButtonType.Icon} icon={icons.IconClose24} />
                </Tooltip>

                <Tooltip tooltip={t('player.next')} className={classes.arrowRightBtn}>
                  <Button
                    onClick={() => sliderRef.current?.slickNext()}
                    type={ButtonType.Icon}
                    icon={icons.IconArrowTriangleRight}
                  />
                </Tooltip>

                <Tooltip tooltip={t('player.prev')} className={classes.arrowLeftBtn}>
                  <Button
                    onClick={() => sliderRef.current?.slickPrev()}
                    type={ButtonType.Icon}
                    icon={icons.IconArrowTriangleLeft}
                  />
                </Tooltip>
              </Box>
            ))}
          </StyledCarousel>
        )}
      </Box>
    </Dialog>
  );
};
