import { action, makeObservable, observable } from 'mobx';

import {
  ISystem,
  CONSTANTS,
  SnackbarActionType,
  Constants,
  Language,
  AudioProcessing,
} from '@/types';
import { constantsService } from '@/services/constants.service';

class ConstantsStore {
  constructor() {
    makeObservable(this);
  }

  @observable constFileMaxCount = 0;
  @observable constFileMaxSize = 0;
  @observable constImageThreshold = 0;
  @observable constImageMinThreshold = 0;
  @observable constSoundThreshold = 0;
  @observable constSoundMinThreshold = 0;
  @observable constExtSystemList: ISystem[] = [];
  @observable constDisplaySetsTab = false;
  @observable isInitialized = false;
  @observable isAllLoaded = false;
  @observable isSideNavCollapsed = false;
  @observable bioscannerApiUrl = '';
  @observable loadFromExternal: boolean | undefined = undefined;
  @observable audioProcessingType: AudioProcessing | undefined = undefined;

  private snackbarAction?: SnackbarActionType;

  @action setConstFileMaxCount = (value: number) => {
    this.constFileMaxCount = value;
  };

  @action setIsSideNavCollapsed = (value: boolean) => {
    this.isSideNavCollapsed = value;
  };

  load = async <T>(key: CONSTANTS): Promise<T> => {
    const value = await constantsService.getConstantValue<T>(key);

    return value;
  };

  saveLanguage = async (lang: Language) => {
    return await constantsService.saveConstantValue<Language>({
      isOwned: true,
      param: CONSTANTS.LANGUAGE,
      value: lang,
    });
  };

  initConstAudioProcessingType = async () => {
    const value = await constantsService.getConstantValue(
      CONSTANTS.AUDIO_PROCESSING_TYPE,
      'Не удалось получить тип обработки для аудио',
      this.snackbarAction
    );

    if (value) {
      this.setConstAudioProcessingType(value as AudioProcessing);

      return value as AudioProcessing;
    }
  };

  @action setConstAudioProcessingType = (value: AudioProcessing) => {
    this.audioProcessingType = value;
  };

  initConstLoadFromExternal = async () => {
    const value = await constantsService.getConstantValue(
      CONSTANTS.LOAD_FROM_EXTERNAL,
      'Не удалось получить',
      this.snackbarAction
    );

    if (value) {
      this.setConstLoadFromExternal(value as boolean);

      return value as boolean;
    }
  };

  @action setConstLoadFromExternal = (value: boolean) => {
    this.loadFromExternal = value;
  };

  initConstBioscannerApiUrl = async () => {
    const value = await constantsService.getConstantValue(
      CONSTANTS.BIOSCANNER_API_URL,
      'Не удалось получить',
      this.snackbarAction
    );

    if (value) {
      this.setConstBioscannerApiUrl(value as string);

      return value as string;
    }
  };

  @action setConstBioscannerApiUrl = (value: string) => {
    this.bioscannerApiUrl = value;
  };

  initConstFileMaxCountApiValue = async () => {
    if (this.snackbarAction) {
      const value = await constantsService.getConstantValue(
        CONSTANTS.ATTACHED_MAX_COUNT,
        'Не удалось получить максимум допустимого количества файлов в запросе',
        this.snackbarAction
      );
      this.setConstFileMaxCount(value as number);

      return value as number;
    }

    return this.constFileMaxCount;
  };

  @action setConstFileMaxSize = (value: number) => {
    this.constFileMaxSize = value;
  };

  initConstFileMaxSizeApiValue = async () => {
    if (this.snackbarAction) {
      const value = await constantsService.getConstantValue(
        CONSTANTS.ATTACHED_MAX_SIZE,
        'Не удалось получить максимальный размер файла в запросе',
        this.snackbarAction
      );
      this.setConstFileMaxSize(value as number);

      return value as number;
    }

    return this.constFileMaxSize;
  };

  @action setConstImageThreshold = (value: number) => {
    this.constImageThreshold = value;
  };

  initConstImageThresholdApiValue = async () => {
    if (this.snackbarAction) {
      const value = await constantsService.getConstantValue(
        CONSTANTS.FACE_IDENT_THRESHOLD,
        'Не удалось получить значение по умолчанию для порога идентификации изображения',
        this.snackbarAction
      );
      this.setConstImageThreshold(value as number);

      return value as number;
    }

    return this.constImageThreshold;
  };

  @action setConstImageMinThreshold = (value: number) => {
    this.constImageMinThreshold = value;
  };

  initConstImageMinThresholdApiValue = async () => {
    if (this.snackbarAction) {
      const value = await constantsService.getConstantValue(
        CONSTANTS.FACE_MIN_IDENT_THRESHOLD,
        'Не удалось получить минимальное допустимое значение для порога идентификации изображения',
        this.snackbarAction
      );
      this.setConstImageMinThreshold(value as number);

      return value as number;
    }

    return this.constImageMinThreshold;
  };

  @action setConstSoundThreshold = (value: number) => {
    this.constSoundThreshold = value;
  };

  initConstSoundThresholdApiValue = async () => {
    if (this.snackbarAction) {
      const value = await constantsService.getConstantValue(
        CONSTANTS.VOICE_IDENT_THRESHOLD,
        'Не удалось получить значение по умолчанию для порога идентификации голоса',
        this.snackbarAction
      );
      this.setConstSoundThreshold(value as number);

      return value as number;
    }

    return this.constSoundThreshold;
  };

  @action setConstSoundMinThreshold = (value: number) => {
    this.constSoundMinThreshold = value;
  };

  initConstSoundMinThresholdApiValue = async () => {
    if (this.snackbarAction) {
      const value = await constantsService.getConstantValue(
        CONSTANTS.VOICE_MIN_IDENT_THRESHOLD,
        'Не удалось получить минимальное допустимое значение для порога идентификации голоса',
        this.snackbarAction
      );
      this.setConstSoundMinThreshold(value as number);

      return value as number;
    }

    return this.constSoundMinThreshold;
  };

  @action setConstExtSystemList = (value: ISystem[]) => {
    this.constExtSystemList = value;
  };

  initConstExtSystemListApiValue = async () => {
    if (this.snackbarAction) {
      const value = await constantsService.getConstantValue<ISystem[]>(
        CONSTANTS.EX_SYSTEM,
        'Не удалось получить перечень источников',
        this.snackbarAction
      );
      this.setConstExtSystemList(value);

      return value;
    }

    return this.constExtSystemList;
  };

  @action setConstDisplaySetsTab = (value: boolean) => {
    this.constDisplaySetsTab = value;
  };

  initConstDisplaySetsTabApiValue = async () => {
    if (this.snackbarAction) {
      const value = await constantsService.getConstantValue<boolean>(
        CONSTANTS.DISPLAY_SETS_TAB,
        'Не удалось получить значение по умолчанию для настройки отображения вкладки множеств',
        this.snackbarAction
      );
      this.setConstDisplaySetsTab(value);

      return value;
    }

    return this.constDisplaySetsTab;
  };

  @action setInitialized = (state: boolean) => {
    this.isInitialized = state;
  };

  @action setAllLoaded = (state: boolean) => {
    this.isAllLoaded = state;
  };

  init = (snackbarAction: SnackbarActionType) => {
    if (snackbarAction) {
      this.snackbarAction = snackbarAction;
      this.setInitialized(true);
    }
  };

  loadAllConstantsValues = async () => {
    try {
      const fileMaxCount = await this.initConstFileMaxCountApiValue();
      const fileMaxSize = await this.initConstFileMaxSizeApiValue();
      const imageThreshold = await this.initConstImageThresholdApiValue();
      const imageMinThreshold = await this.initConstImageMinThresholdApiValue();
      const soundThreshold = await this.initConstSoundThresholdApiValue();
      const soundMinThreshold = await this.initConstSoundMinThresholdApiValue();
      const extSystemList = await this.initConstExtSystemListApiValue();
      const displaySetsTab = await this.initConstDisplaySetsTabApiValue();
      this.setAllLoaded(true);

      const result: Constants = {
        constFileMaxCount: fileMaxCount,
        constFileMaxSize: fileMaxSize,
        constImageThreshold: imageThreshold,
        constImageMinThreshold: imageMinThreshold,
        constSoundThreshold: soundThreshold,
        constSoundMinThreshold: soundMinThreshold,
        constExtSystemList: extSystemList,
        constDisplaySetsTab: displaySetsTab,
      };

      return result;
    } catch (error) {
      throw error;
    }
  };

  clear = () => {
    this.setInitialized(false);
    this.setAllLoaded(false);
    this.setConstFileMaxCount(0);
    this.setConstFileMaxSize(0);
    this.setConstImageThreshold(0);
    this.setConstImageMinThreshold(0);
    this.setConstSoundThreshold(0);
    this.setConstSoundMinThreshold(0);
    this.setConstExtSystemList([]);
    this.setConstDisplaySetsTab(false);
  };
}

export default ConstantsStore;
