import { observer } from 'mobx-react';
import { Stack } from '@mui/material';
import { Button, ButtonType, icons, Separator, Switch, Toolbar } from 'stc-ui-kit';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { VoiceRecorder } from './VoiceRecorder';
import { useCardCreationContext } from '../../../hooks/useCardCreationContext';
import { AudioDeviceSelect } from './AudioDeviceSelect';
import { VideoDeviceSelect } from './VideoDeviceSelect';
import { DeviceSetupModal } from './DeviceSetupModal';
import { InteractiveModeCamera } from './InteractiveModeCamera/InteractiveModeCamera';
import { useDataStore } from '@/Providers/StoreProvider';
import { QuestionnaireSelector } from './QuestionnaireSelector';
import { DocumentsAndAttachedFiles } from '../DocumentsAndAttachedFiles/DocumentsAndAttachedFiles';

export const DeviceRecording = observer(function DeviceRecording() {
  const { t } = useTranslation();
  const { deviceRecordingData } = useCardCreationContext();
  const [isSetupModalOpen, setIsSetupModalOpen] = useState(false);
  const { rootMediaDevicesStore } = useDataStore();
  const { componentStorageId, toggleIsRecording, isRecordingActive } = rootMediaDevicesStore;

  const { isAutoMode, setIsAutoMode, isAutoModeBlocked } = deviceRecordingData;

  const {
    selectedAudioInputDevice,
    selectedVideoInputDevice,
    isAudioDevicePermissionGranted,
    isVideoDevicePermissionGranted,
  } = rootMediaDevicesStore;

  const isRecordingActiveInAnotherCard = localStorage.getItem(componentStorageId || '') === 'true';

  return (
    <>
      <Toolbar data-testid=''>
        <Button
          isDisabled={
            !selectedAudioInputDevice ||
            !selectedVideoInputDevice ||
            (isRecordingActiveInAnotherCard && !isRecordingActive)
          }
          onClick={toggleIsRecording}
          icon={isRecordingActive ? icons.IconStopRecording : icons.IconPlayCircle}
        >
          {isRecordingActive ? t('cards.stopRecording') : t('cards.startRecording')}
        </Button>

        <Separator vertical />

        <VideoDeviceSelect />

        <Separator vertical />

        <AudioDeviceSelect />

        <Separator vertical />

        <Button
          isDisabled={!isAudioDevicePermissionGranted || !isVideoDevicePermissionGranted}
          icon={icons.IconSettings}
          type={ButtonType.Icon}
          onClick={() => setIsSetupModalOpen(true)}
        />

        {isRecordingActive && (
          <Stack marginLeft={'auto'} direction={'row'} gap={2}>
            <Stack direction={'row'} gap={1} alignItems={'center'}>
              <span>{t('cards.autoShootMode')}</span>
              <Switch
                isChecked={isAutoMode}
                onChange={setIsAutoMode}
                isDisabled={isAutoModeBlocked}
                data-testid=''
              />
            </Stack>

            <Separator vertical />

            <QuestionnaireSelector />
          </Stack>
        )}
      </Toolbar>

      {isSetupModalOpen && <DeviceSetupModal onClose={() => setIsSetupModalOpen(false)} />}

      {!isRecordingActive && <DocumentsAndAttachedFiles tab='deviceRecording' />}

      {isRecordingActive && (
        <Stack>
          <InteractiveModeCamera />
          <VoiceRecorder recorderControls={deviceRecordingData.recorderControls} />
        </Stack>
      )}
    </>
  );
});
