import { ButtonDropdownList, GroupedListItems, icons, ListItemType } from 'stc-ui-kit';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useDataStore } from '@/Providers/StoreProvider';

export const AudioDeviceSelect = observer(function AudioDeviceSelect() {
  const { t } = useTranslation();
  const { rootMediaDevicesStore } = useDataStore();

  const {
    allDevicesByType,
    selectedAudioInputDevice,
    setSelectedAudioInputDeviceId,
    isAudioDevicePermissionGranted,
  } = rootMediaDevicesStore;

  const audioDeviceItems: GroupedListItems = allDevicesByType.audio.map((device) => ({
    id: device.deviceId,
    name: device.label,
    type: ListItemType.Simple,
    onClick: () => setSelectedAudioInputDeviceId(device.deviceId),
  }));

  return (
    <ButtonDropdownList
      isDisabled={!isAudioDevicePermissionGranted}
      items={[audioDeviceItems]}
      label={
        selectedAudioInputDevice ? selectedAudioInputDevice.label : t('cards.microphoneNotSelected')
      }
      icon={icons.IconMicrophone}
      data-testid=''
    />
  );
});
