import { useDataStore } from '@/Providers/StoreProvider';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { ButtonDropdownList, GroupedListItems, ListItemType, icons } from 'stc-ui-kit';

export const VideoDeviceSelect = observer(function VideoDeviceSelect() {
  const { t } = useTranslation();
  const { rootMediaDevicesStore } = useDataStore();

  const {
    allDevicesByType,
    setSelectedVideoInputDeviceId,
    selectedVideoInputDevice,
    isVideoDevicePermissionGranted,
  } = rootMediaDevicesStore;

  const videoDeviceItems: GroupedListItems = allDevicesByType.video.map((device) => ({
    id: device.deviceId,
    name: device.label,
    type: ListItemType.Simple,
    onClick: () => setSelectedVideoInputDeviceId(device.deviceId),
  }));

  return (
    <ButtonDropdownList
      isDisabled={!isVideoDevicePermissionGranted}
      items={[videoDeviceItems]}
      label={
        selectedVideoInputDevice ? selectedVideoInputDevice.label : t('cards.cameraNotSelected')
      }
      icon={icons.IconPhoto}
      data-testid=''
    />
  );
});
