import { makeStyles } from '@/hooks/makeStyles';
import { Button, Stack } from '@mui/material';
import { Children, PropsWithChildren, useEffect, useRef, useState } from 'react';
import { Icon, icons } from 'stc-ui-kit';

const useStyles = makeStyles()(() => ({
  scrollButton: {
    position: 'absolute',
    zIndex: 5,
    height: '100%',
    minWidth: 0,
    padding: 0,
    background: 'black',
    borderRadius: 0,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'black',
    },
  },
}));

export function InteractivePhotosSlider({
  children,
  photoWidth,
}: PropsWithChildren & { photoWidth: number }) {
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const scrollElementRef = useRef<HTMLDivElement | null>(null);
  const [isScrollLeftDisabled, setIsScrollLeftDisabled] = useState(true);
  const [isScrollRightDisabled, setIsScrollRightDisabled] = useState(true);

  const { classes } = useStyles();

  const childrenCount = Children.count(children);

  useEffect(() => {
    updateScrollButtonsState();
  }, [childrenCount]);

  const updateScrollButtonsState = () => {
    if (!scrollContainerRef.current || !scrollElementRef.current) return;

    setIsScrollLeftDisabled(scrollContainerRef.current?.scrollLeft === 0);
    setIsScrollRightDisabled(
      Math.abs(
        scrollContainerRef.current.scrollLeft +
          scrollContainerRef.current.offsetWidth -
          scrollElementRef.current.offsetWidth
      ) < 1
    );
  };

  const handleScrollButtonClick = (scrollOffset: number) => {
    if (!scrollContainerRef.current) return;

    scrollContainerRef.current.scrollLeft += scrollOffset;
  };

  return (
    <Stack position={'relative'} overflow={'hidden'}>
      <Button
        onClick={() => handleScrollButtonClick(-photoWidth * 1.5)}
        className={classes.scrollButton}
        variant='contained'
        sx={{ left: 0, display: isScrollLeftDisabled ? 'none' : undefined }}
      >
        <Icon icon={icons.IconArrowTriangleLeft} />
      </Button>

      <Button
        onClick={() => handleScrollButtonClick(photoWidth * 1.5)}
        className={classes.scrollButton}
        variant='contained'
        sx={{ right: 0, display: isScrollRightDisabled ? 'none' : undefined }}
      >
        <Icon icon={icons.IconArrowTriangleRight} />
      </Button>

      <Stack
        style={{ overflowX: 'scroll', scrollBehavior: 'smooth' }}
        ref={scrollContainerRef}
        onScroll={updateScrollButtonsState}
      >
        <Stack direction={'row'} gap={2} width={'fit-content'} ref={scrollElementRef}>
          {children}
        </Stack>
      </Stack>
    </Stack>
  );
}
