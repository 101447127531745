import { Dispatch, SetStateAction, useEffect } from 'react';
import { useDataStore } from '@/Providers/StoreProvider';
import { ICard } from '@/types/card';
import { FieldValues, UseFormReset } from 'react-hook-form';
import { isEqual } from 'lodash';
import { showInfoNotification } from '@/utils/notifications';
import { useTranslation } from 'react-i18next';

export const useCardSseMessages = ({
  cardData,
  setCardData,
  reset,
}: {
  cardData: ICard | null;
  setCardData: Dispatch<SetStateAction<ICard | null>>;
  reset: UseFormReset<FieldValues>;
}) => {
  const { sseStore } = useDataStore();
  const { t } = useTranslation();

  useEffect(() => {
    const message = sseStore.cardMessage;
    if (!message) return;
    if (message.card.id !== cardData?.id) return;

    const faceModelChanged =
      !!message.card.faceModel && cardData?.faceModel?.id !== message.card.faceModel.id;

    const faceModelDeleted = !message.card.faceModel && !!cardData?.faceModel;

    const voiceModelChanged =
      !!message.card.voiceModel && cardData?.voiceModel?.id !== message.card.voiceModel.id;

    const voiceModelDeleted = !message.card.voiceModel && !!cardData?.voiceModel;

    let notificationHeader = '';
    if (faceModelChanged) notificationHeader = t('messages.faceModelEdit');
    if (faceModelDeleted) notificationHeader = t('messages.faceModelDeleted');
    if (voiceModelChanged) notificationHeader = t('messages.audioModelEdit');
    if (voiceModelDeleted) notificationHeader = t('messages.audioModelDeleted');

    if (notificationHeader) {
      showInfoNotification({ header: notificationHeader });
    }

    if (message.fromUserDevice) {
      setCardData(message.card);
      reset(message.card.data);
      if (!isEqual(message.card.data, cardData.data)) {
        showInfoNotification({ header: t('multipass.cardSuccessfullyEdited') });
      }
    } else {
      setCardData((prev) => {
        if (!prev) return prev;

        if (faceModelChanged || faceModelDeleted || voiceModelChanged || voiceModelDeleted) {
          return {
            ...prev,
            faceModel:
              faceModelChanged || faceModelDeleted ? message.card.faceModel : prev?.faceModel,
            voiceModel:
              voiceModelChanged || voiceModelDeleted ? message.card.voiceModel : prev?.voiceModel,
          };
        }

        return prev;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sseStore.cardMessage]);
};
