import { useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { ButtonDropdownList, icons, ListItemType, GroupedListItems, Separator } from 'stc-ui-kit';
import { Stack } from '@mui/material';
import { Questionnaire, localizedQuestionnaire } from '@/types/cardTemplate';
import { QuestionnaireModal } from './QuestionnaireModal';
import { useCardCreationContext } from '../../../hooks/useCardCreationContext';

export const QuestionnaireSelector = observer(() => {
  const { t, i18n } = useTranslation();
  const { selectedTemplate } = useCardCreationContext();
  const [openedQuestionnaire, setOpenedQuestionnaire] = useState<Questionnaire | null>(null);

  const questionnaires =
    selectedTemplate?.data.questionnaires?.[i18n.language as keyof localizedQuestionnaire] || [];

  const questionnairesMenuItems: GroupedListItems = questionnaires.map((q) => ({
    id: q.title,
    name: q.title,
    type: ListItemType.Simple,
    onClick: () => setOpenedQuestionnaire(q),
  }));

  return (
    <>
      <ButtonDropdownList
        items={[questionnairesMenuItems]}
        isDisabled={!questionnaires.length}
        label={t('cards.questionnaire')}
        icon={icons.IconDialog}
        data-testid=''
        placement='bottom-end'
      />

      <Separator vertical />

      {openedQuestionnaire && (
        <QuestionnaireModal
          questionnaire={openedQuestionnaire}
          onClose={() => setOpenedQuestionnaire(null)}
        />
      )}
    </>
  );
});
