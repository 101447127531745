import { appColors } from '@/Providers/ThemeProvider/colors';
import { ReactComponent as FaceModelIcon } from 'assets/icons/face.svg';
import { SvgIcon as SvgIconCustom } from 'components/SvgIcon/SvgIcon';
import Delete from '@mui/icons-material/Delete';
import { Box, IconButton, Stack } from '@mui/material';

export const GalleryCard = ({ photoSrc }: { photoSrc: string }) => {
  return (
    <Box
      height={'318px'}
      width={'222px'}
      border={`1px solid ${appColors.steal200}`}
      borderRadius={'4px'}
      margin={'8px 8px'}
    >
      <img
        src={photoSrc}
        alt=''
        style={{
          height: '284px',
          width: '222px',
          objectFit: 'cover',
        }}
      />
      <Stack
        display={'flex'}
        justifyContent={'flex-end'}
        direction={'row'}
        alignItems={'center'}
        height={'34px'}
        marginTop={-1}
      >
        <SvgIconCustom IconComponent={FaceModelIcon} color='green' size={{ w: 22, h: 22 }} />
        <IconButton size='small'>
          <Delete sx={{ color: appColors.black600 }} />
        </IconButton>
      </Stack>
    </Box>
  );
};
