import { EFileProcessStatus } from '@/types/file';
import { getStatusIcon } from './utils/getStatusIcon';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { ECardExportStatus } from '@/types/card';

export function StatusWithIcon({
  status,
  type,
}: {
  status: EFileProcessStatus | ECardExportStatus;
  type: 'card' | 'fileInCard' | 'file';
}) {
  const { t } = useTranslation();

  const statusText = useMemo(() => {
    switch (type) {
      case 'card':
        return t([
          `cards.exportStatus.${status as ECardExportStatus}`,
          'cards.exportStatus.unknown',
        ]);
      case 'fileInCard':
        return t([
          `attachedFileStatus.${status as EFileProcessStatus}`,
          'attachedFileStatus.unknown',
        ]);
      case 'file':
        return t([
          `multipass.fileProcessStatus.${status as EFileProcessStatus}`,
          'multipass.fileProcessStatus.unknown',
        ]);
    }
  }, [type, t, status]);

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {getStatusIcon({ status, isCard: type === 'card' })}
      <span style={{ whiteSpace: 'nowrap' }}>{statusText}</span>
    </div>
  );
}
