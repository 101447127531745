import { useImageErrorPlaceholder } from '@/hooks/useImageErrorPlaceholder';
import { ModelQualityIconComponent } from '@/modules/multipass/components/icons/ModelQualityIconComponent';
import { appColors } from '@/Providers/ThemeProvider/colors';
import { CardPhoto } from '@/types/card';
import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Button, ButtonType, Icon, icons, UiKitColor } from 'stc-ui-kit';
import { ReactComponent as ZoomImageIcon } from 'assets/icons/zoomImage.svg';
import { makeStyles } from '@/hooks/makeStyles';
import { SvgIcon } from '@/components/SvgIcon/SvgIcon';
import { useCardCreationContext } from '@/modules/multipass/CardCreation/hooks/useCardCreationContext';

const useStyles = makeStyles()(() => ({
  imageWrapper: {
    '& .actionPanel': {
      transform: 'translateY(110%)',
      transition: 'transform .2s ease',
    },
    '&:hover .actionPanel': {
      transform: 'none',
    },
  },
}));

export function InteractiveModePhotoWrapper({
  photo,
  onFullScreenOpen,
  index,
}: {
  photo: CardPhoto;
  onFullScreenOpen: (index: number) => void;
  index: number;
}) {
  const { onError } = useImageErrorPlaceholder();
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { setCardModel, deleteFiles } = useCardCreationContext();

  return (
    <Box
      position='relative'
      width={'100%'}
      height={'100%'}
      onDoubleClick={() => onFullScreenOpen(index)}
      sx={{
        background: appColors.black,
        border: photo.isMain ? `4px solid ${appColors.bgPrimary}` : undefined,
        overflow: 'hidden',
      }}
      className={classes.imageWrapper}
    >
      <Box position='absolute' top='0' right='0' padding='8px' display='flex' zIndex='3'>
        {photo.faceData?.length === 1 && (
          <ModelQualityIconComponent
            quality={photo.faceData?.[0]?.quality}
            type='img'
            portraitCharacteristics={photo.faceData?.[0]?.portraitCharacteristics}
          />
        )}
      </Box>

      <img
        src={photo.path}
        alt=''
        style={{
          height: '100%',
          width: '100%',
          objectFit: 'cover',
          objectPosition: 'center',
        }}
        onError={onError}
      />

      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        position={'absolute'}
        bottom={0}
        width={'100%'}
        height={'40px'}
        paddingInline={'8px'}
        sx={{
          backgroundColor: appColors.black600,
        }}
        className='actionPanel'
      >
        <Button
          icon={icons.IconDelete}
          type={ButtonType.Icon}
          onClick={() => void deleteFiles([photo.id], photo.fileGroup)}
        />

        {photo.isMain && (
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <Icon icon={icons.IconTaskCompleted} iconColor={UiKitColor.Indigo600} />
            <Typography fontSize={'12px'} fontWeight={500}>
              {t('cards.faceModel')}
            </Typography>
          </Stack>
        )}

        {!photo.isMain && (
          <Button
            type={ButtonType.Text}
            isDisabled={photo.faceData.length !== 1}
            onClick={() => setCardModel(photo.faceData[0].modelId)}
          >
            {t('multipass.setAsMainPhoto')}
          </Button>
        )}

        <Button
          icon={() => <SvgIcon IconComponent={ZoomImageIcon} color={appColors.white600} />}
          type={ButtonType.Icon}
          onClick={() => onFullScreenOpen(index)}
        />
      </Stack>
    </Box>
  );
}
