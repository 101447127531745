import { GridStore } from '@/stores/grid.store';
import { User } from '@/types/user';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ButtonSize,
  ButtonType,
  Checkbox,
  ConfirmationType,
  icons,
  Separator,
  useConfirmationModal,
} from 'stc-ui-kit';
import { AddUserModal } from './AddUserModal';
import { IGroup } from '@/types/group';
import { useGroupsPageContext } from './useGroupsPageContext';
import { observer } from 'mobx-react';
import groupService from '@/services/group.service';
import { useUserPermissionsContext } from '@/hooks/useUserPermissionsContext';
import { permissionControl } from '@/types/permissionControl';
import { showInfoNotification } from '@/utils/notifications';

interface IProps {
  store: GridStore<User>;
}

export const UsersBlockHeader = observer(({ store }: IProps) => {
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const { fetchGroupUsers, groups, selectedGroupsIds } = useGroupsPageContext();
  const { havePermissions } = useUserPermissionsContext();
  const { addUserButton, excludeButton } = permissionControl.administration.groups;

  const selectedGroup: IGroup | undefined = groups.find(
    (g: IGroup) => g.id.toString() === selectedGroupsIds[0]
  );

  const { t } = useTranslation();
  const { showConfirmationModal } = useConfirmationModal();

  const handleExcludeClick = useCallback(async () => {
    const submitType = await showConfirmationModal({
      title: t('groups.excludeFromGroup'),
      text: t('groups.confirmExclude'),
      note: t('groups.confirmExcludeNote'),
      primaryButtonText: t('groups.exclude'),
      cancelButtonText: t('cancel'),
    });

    if (submitType === ConfirmationType.PrimaryAction) {
      const users = store.checkedItems;
      await groupService.addOrExcludeUserfromGroup(
        users.map((u) => u.id),
        null
      );

      showInfoNotification({
        header:
          users.length === 1
            ? t('messages.userWasExcluded', {
                username: users[0].username,
                groupName: selectedGroup?.groupName || '',
              })
            : t('messages.usersWereExcluded', {
                usernames: users.map((u) => u.username).join(', '),
                groupName: selectedGroup?.groupName || '',
              }),
      });

      fetchGroupUsers();
    }
  }, [fetchGroupUsers, selectedGroup?.groupName, t, showConfirmationModal, store]);

  const selectedUsersCount = store.checkedItems.length;

  return (
    <>
      <span style={{ whiteSpace: 'nowrap' }}>{selectedGroup?.groupName || ''}</span>

      {havePermissions(addUserButton) && (
        <>
          <Separator vertical />

          <Button
            size={ButtonSize.Small}
            icon={icons.IconAdd}
            onClick={() => setIsAddUserModalOpen(true)}
          >
            {t('addUser')}
          </Button>
        </>
      )}

      <Separator vertical />

      <Checkbox
        label={`${t('selected')}: ${selectedUsersCount}/${store.data.length}`}
        isChecked={selectedUsersCount > 0}
        onChange={() => {
          store.resetChecked();
        }}
        data-testid=''
      />

      {havePermissions(excludeButton) && (
        <>
          <Separator vertical />

          <Button
            type={ButtonType.Text}
            isDisabled={!selectedUsersCount}
            onClick={handleExcludeClick}
          >
            {t('groups.excludeFromGroup')}
          </Button>
        </>
      )}

      {isAddUserModalOpen && (
        <AddUserModal
          onClose={() => setIsAddUserModalOpen(false)}
          currentValue={store.data.map((u) => u.id.toString())}
        />
      )}
    </>
  );
});
