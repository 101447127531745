import { appColors } from '@/Providers/ThemeProvider/colors';
import { convertSecondsToTime } from '@/utils/convertSecondsToTime';
import { Box, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { LiveAudioVisualizer } from 'react-audio-visualize';
import { useTranslation } from 'react-i18next';
import { Icon, icons, Tooltip, UiKitColor } from 'stc-ui-kit';
import { MAX_AUDIO_RECORD_SECONDS } from '../../../constants';

interface IRecorderControls {
  recorder: MediaRecorder | null;
  isPaused: boolean;
  isRecording: boolean;
  togglePauseResume: () => void;
  recordingTimeMs: number;
}

export function VoiceRecorder({ recorderControls }: { recorderControls: IRecorderControls }) {
  const { togglePauseResume, isPaused, isRecording, recorder, recordingTimeMs } = recorderControls;

  const { t } = useTranslation();

  // это нужно, чтобы осциллограмма принимала ширину экрана
  useEffect(() => {
    const canvas = document.getElementById('voiceRecorderCanvasWrapper')?.querySelector('canvas');
    if (!canvas) return;

    canvas.style.width = '100%';
    canvas.style.height = '40px';
    canvas.width = canvas.offsetWidth;
    canvas.height = canvas.offsetHeight;
  }, [recorder]);

  return (
    <Stack>
      <Stack
        height={'50px'}
        width={'100%'}
        position={'relative'}
        justifyContent={'center'}
        sx={{
          backgroundColor: isRecording ? undefined : appColors.grey700,
        }}
      >
        {recorder && (
          <div id='voiceRecorderCanvasWrapper'>
            <LiveAudioVisualizer
              fftSize={2048}
              barColor={appColors.grey}
              mediaRecorder={recorder}
            />
          </div>
        )}
      </Stack>

      <Stack
        direction={'row'}
        gap={2}
        alignItems={'center'}
        paddingInline={2}
        sx={{ backgroundColor: appColors.grey, height: '40px' }}
      >
        <Typography color={appColors.black} fontSize={'12px'} fontWeight={500}>
          {convertSecondsToTime(
            isRecording ? Math.floor(recordingTimeMs / 1000) : MAX_AUDIO_RECORD_SECONDS,
            false
          )}
        </Typography>

        <Tooltip tooltip={recorder?.state === 'paused' ? t('cards.play') : t('cards.pause')}>
          <Box sx={{ opacity: isRecording ? 1 : 0.6 }}>
            <Icon
              icon={isPaused || !isRecording ? icons.IconPlayCircle : icons.IconPauseCircle}
              isCircleWrapped
              iconColor={UiKitColor.White800}
              backgroundColor={UiKitColor.Teal800}
              onClick={isRecording ? togglePauseResume : undefined}
            />
          </Box>
        </Tooltip>

        {!!isRecording && (
          <Stack direction={'row'} alignItems={'center'}>
            <span
              style={{
                width: '10px',
                height: '10px',
                marginRight: '5px',
                borderRadius: '50%',
                backgroundColor: isPaused ? appColors.grey700 : '#ED606C',
              }}
            />
            <Typography color={appColors.black} fontSize={'12px'} fontWeight={500}>
              {isPaused ? t('cards.recordingIsPaused') : t('cards.isRecording')}
            </Typography>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}
