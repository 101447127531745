import { IFileLink, ITableItem } from '@/types/file';
import { useTranslation } from 'react-i18next';
import { EmptyPlaceholder, TableColumn, TableRow } from 'stc-ui-kit';
import { DateCell } from '@/components/queryPage/ExportModal/DateCell';
import { useCardCreationContext } from '../../../hooks/useCardCreationContext';
import { useDataStore } from '@/Providers/StoreProvider';
import { observer } from 'mobx-react';
import { StatusWithIcon } from '@/modules/multipass/StatusWithIcon';
import { StcGridTable } from '@/components/Grid/StcGridTable';
import { useGridStore } from '@/hooks/grid/useGridStore';
import { useEffect } from 'react';
import { DocumentsTableTab } from './DocumentsAndAttachedFiles';
import { ReactComponent as NoImageSvg } from '@/assets/icons/noImagePlaceholder.svg';

interface Props {
  tab: DocumentsTableTab;
  onRowOpen: (item: TableRow<ITableItem>) => void;
}

export const DocumentsAndAttachedFilesTable: React.FC<Props> = observer(({ tab, onRowOpen }) => {
  const { fileLinks, cardData } = useCardCreationContext();
  const { uiStore } = useDataStore();
  const { t } = useTranslation();

  const cardId = cardData?.id || 0;

  const handleSelect = (itemsIds: string[]) => {
    uiStore.setSelectedEntities(
      cardId,
      tab,
      itemsIds.map((id) => Number(id))
    );
  };

  const { store } = useGridStore<IFileLink>({
    dataProvider: () => new Promise((res) => res({ data: fileLinks })),
  });

  useEffect(() => {
    store.setData({ data: fileLinks });
  }, [fileLinks, store]);

  const columns: Array<TableColumn<IFileLink>> = [
    {
      id: 'filename',
      title: t('multipass.fileName'),
      isVisible: true,
      width: 370,
      renderRowCell: (row) =>
        (decodeURIComponent(row.path).split('/').pop() || '').split('.').slice(0, -1).join('.'),
    },
    {
      id: 'extension',
      title: t('multipass.extension'),
      isVisible: true,
      width: 110,
      renderRowCell: (row) => (row.path?.split('/').pop() || '').split('.').pop() || '',
    },
    {
      id: 'createdTime',
      title: t('multipass.additionDate'),
      isVisible: true,
      width: 145,
      renderRowCell: (row) => <DateCell dateTime={row.createdTime ?? ''} component={'span'} />,
    },
    {
      id: 'creator',
      title: t('multipass.addedBy'),
      isVisible: true,
      width: 145,
      renderRowCell: (row) => row.creator.username,
    },
    {
      id: 'status',
      title: t('multipass.status'),
      isVisible: true,
      width: 150,
      renderRowCell: (row) => <StatusWithIcon status={row.status} type={'fileInCard'} />,
    },
  ];

  const renderEmptyPlaceholder = () => {
    return tab === 'deviceRecording' ? (
      <EmptyPlaceholder
        title={t('cards.noItemsToShow')}
        label={t('cards.startCollectingBiometrics')}
        icon={() => <NoImageSvg />}
      />
    ) : (
      <EmptyPlaceholder title={t('cards.noItemsAvailable')} />
    );
  };

  return (
    <StcGridTable
      store={store}
      columns={columns}
      tableProps={{
        onRowDoubleClick: onRowOpen,
        onSelect: handleSelect,
        renderEmptyPlaceholder: renderEmptyPlaceholder,
        isBorderDisabled: true,
      }}
    />
  );
});
