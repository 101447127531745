import { cardsService } from '@/services/cards.service';
import { format } from 'date-fns';
import { useCallback, useEffect, useRef, useState } from 'react';
import { MAX_AUDIO_RECORD_SECONDS, RECORDING_TIMESLICE } from '../constants';
import { useDataStore } from '@/Providers/StoreProvider';
import {
  IMediaRecorder,
  MediaRecorder as ExtendableMediaRecorder,
  register,
} from 'extendable-media-recorder';
import { connect } from 'extendable-media-recorder-wav-encoder';

const CHUNK_HEADER_LENGTH = 44; //первые 44 байта в extendable-media-recorder-wav-encoder содержат заголовки

export const useVoiceRecording = ({ cardId }: { cardId: number | undefined }) => {
  const { rootMediaDevicesStore } = useDataStore();
  const {
    currentRecordSessionId,
    isRecordingActive,
    selectedAudioInputDeviceId,
    getMediaDeviceStream,
  } = rootMediaDevicesStore;

  const blobPromise = useRef<Promise<null> | null>(null);
  const blobPromiseResolver = useRef<((value: PromiseLike<null> | null) => void) | null>(null);

  const recorder = useRef<IMediaRecorder | null>(null);
  /**
   * либа, которая рисует осциллограмму, не принимает IMediaRecorder
   */
  const recorderForOscillogram = useRef<MediaRecorder | null>(null);

  const [isRecording, setIsRecording] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [recordingTimeMs, setRecordingTimeMs] = useState(0);

  const timeInterval = useRef<NodeJS.Timeout>();
  const recordingStartTimestamp = useRef<number | null>(null);
  const dataChunks = useRef<Blob[]>([]);
  const chunkHeader = useRef<ArrayBuffer | null>(null);

  useEffect(() => {
    const initAndStart = async () => {
      if (!selectedAudioInputDeviceId) return;

      try {
        await register(await connect());
      } catch (error) {
        console.error(error);
      }

      const stream = await getMediaDeviceStream('audio', selectedAudioInputDeviceId);
      recorderForOscillogram.current = new MediaRecorder(stream);
      recorder.current = new ExtendableMediaRecorder(stream, { mimeType: 'audio/wav' });

      /**
       * по какой-то причине onpause и onresume не работают,
       * поэтому эти события обрабатываются в togglePauseResume
       */
      recorder.current.ondataavailable = async ({ data }) => {
        dataChunks.current.push(data);
        if (recorder.current?.state === 'inactive') {
          const result = new Blob([...dataChunks.current], { type: dataChunks.current[0].type });

          if (!cardId) return;

          const file = new File([result], `voice_${format(new Date(), 'HH-mm-ss_dd-MM-yyyy')}.wav`);

          void cardsService.createMediaFileProcesses({
            cardId: cardId,
            fileGroup: 'VOICE',
            file,
            recordSessionId: currentRecordSessionId,
          });

          blobPromiseResolver.current?.(null);
          dataChunks.current = [];
          chunkHeader.current = null;
        } else {
          if (!chunkHeader.current) {
            chunkHeader.current = (await data.arrayBuffer()).slice(0, CHUNK_HEADER_LENGTH);
            //TODO send data to api
          } else {
            const content = await data.arrayBuffer();
            const result = new Blob([chunkHeader.current, content], { type: data.type });
            //TODO send result to api
          }
        }
      };

      recorder.current.onstart = () => {
        setIsRecording(true);
        setIsPaused(false);
        recorderForOscillogram.current?.start();
        startOrResumeTimer();
      };

      recorder.current.onstop = () => {
        /**
         * если делать stop(), то в осциллограмме отображаются остатки стрима,
         * а если делать pause(), то осциллограмма пустая.
         */
        recorderForOscillogram.current?.pause();
        // recorderForOscillogram.current?.stop();
        // recorderForOscillogram.current?.stream.getTracks().forEach((track) => track.stop());

        stopOrPauseTimer();
        setIsRecording(false);
        setIsPaused(false);
        setRecordingTimeMs(0);
      };

      recorder.current.start(RECORDING_TIMESLICE);
    };

    if (isRecordingActive) {
      void initAndStart();
    } else {
      recorder.current?.stop();
    }
  }, [
    isRecordingActive,
    getMediaDeviceStream,
    selectedAudioInputDeviceId,
    cardId,
    currentRecordSessionId,
  ]);

  const startOrResumeTimer = () => {
    recordingStartTimestamp.current = new Date().getTime();

    timeInterval.current = setInterval(() => {
      const now = new Date().getTime();
      const diff = now - (recordingStartTimestamp.current || now);
      recordingStartTimestamp.current = now;
      setRecordingTimeMs((prev) => prev + diff);
    }, 1000);
  };

  const stopOrPauseTimer = () => {
    clearInterval(timeInterval.current);
  };

  const togglePauseResume = () => {
    if (recorder.current?.state === 'paused') {
      recorder.current.resume();
      recorderForOscillogram.current?.resume();
      setIsPaused(false);
      startOrResumeTimer();
    } else {
      recorder.current?.pause();
      recorderForOscillogram.current?.pause();
      setIsPaused(true);
      stopOrPauseTimer();
    }
  };

  useEffect(() => {
    if (Math.floor(recordingTimeMs / 1000) >= MAX_AUDIO_RECORD_SECONDS) {
      recorder.current?.stop();
    }
  }, [recordingTimeMs]);

  /**
   * между вызовом stop и формированием блоба (событие ondataavailable) проходит время.
   * эта функция дожидается формирования блоба.
   */
  const asyncStopPlayerRecording = useCallback(async () => {
    console.log('asyncStopPlayerRecording');
    recorder.current?.stop();

    if (!blobPromise.current) {
      blobPromise.current = new Promise((resolve) => {
        blobPromiseResolver.current = resolve;
      });
    }

    await blobPromise.current;
  }, []);

  return {
    recorderControls: {
      recorder: recorderForOscillogram.current,
      isPaused,
      isRecording,
      togglePauseResume,
      recordingTimeMs,
    },
    asyncStopPlayerRecording,
  };
};
