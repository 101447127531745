import { cardsService } from '@/services/cards.service';
import { format } from 'date-fns';
import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { useDataStore } from '@/Providers/StoreProvider';
import { AUTO_MODE_PHOTO_INTERVAL, MAX_AUTO_MODE_PHOTOS } from '../constants';
import { CardPhoto, ICard } from '@/types/card';
import { EFileProcessStatus, IFileLink } from '@/types/file';
import { getModelQuality } from '../../utils/getModelQuality';
import { EntryTab } from '@/types/ui.types';
import { SseMessage, SseMessageType } from '@/types/sse';

export const useTakePictures = ({
  isAutoMode,
  cardData,
  photos,
  activeTab,
  setIsAutoMode,
  setIsAutoModeBlocked,
}: {
  isAutoMode: boolean;
  cardData: ICard | null;
  photos: CardPhoto[];
  activeTab: EntryTab;
  setIsAutoMode: Dispatch<SetStateAction<boolean>>;
  setIsAutoModeBlocked: Dispatch<SetStateAction<boolean>>;
}) => {
  const { rootMediaDevicesStore, sseStore } = useDataStore();
  const {
    currentRecordSessionId,
    isRecordingActive,
    getMediaDeviceStream,
    selectedVideoInputDeviceId,
  } = rootMediaDevicesStore;

  const { cardFileMessage } = sseStore;

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const autoModePhotos = useRef<IFileLink[]>([]);

  /**
   * это замененные фото для autoModePhotos, загрузки которых мы ожидаем.
   * это нужно, чтобы старые фото отображались, пока в photos не подгрузятся новые.
   * идея в том, что если фото из autoModePhotos нужно переснять, то новое фото
   * записывается в expectedAutoModePhotos под тем же индексом, что и старое фото.
   * и когда новое фото загрузится, оно перезаписывает соответствующий элемент в autoModePhotos
   * и заменяется на null из expectedAutoModePhotos.
   */
  const expectedAutoModePhotos = useRef<(IFileLink | null)[]>(
    Array<null>(MAX_AUTO_MODE_PHOTOS).fill(null)
  );

  const manualModePhotoIds = useRef<number[]>([]);

  const [autoModePhotoSseMessage, setAutoModePhotoSseMessage] = useState<SseMessage | null>(null);

  /**
   * невидимые элементы нужны, чтобы камера могла фоткать вне вкладки "запись с устройств"
   */
  useEffect(() => {
    const initElements = async () => {
      if (isRecordingActive && selectedVideoInputDeviceId) {
        const video = document.createElement('video');

        const stream = await getMediaDeviceStream('video', selectedVideoInputDeviceId);
        video.srcObject = stream;
        await video.play();

        video.autoplay = true;
        video.style.position = 'fixed';
        video.style.opacity = '0';
        video.style.zIndex = '-10';
        document.body.appendChild(video);
        videoRef.current = video;

        const canvas = document.createElement('canvas');
        canvas.style.position = 'fixed';
        canvas.style.opacity = '0';
        canvas.style.zIndex = '-10';
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        document.body.appendChild(canvas);
        canvasRef.current = canvas;
      }
    };

    void initElements();

    return () => {
      videoRef.current?.remove();
      canvasRef.current?.remove();
    };
  }, [getMediaDeviceStream, isAutoMode, isRecordingActive, selectedVideoInputDeviceId]);

  const takePicture = useCallback(async () => {
    if (!canvasRef.current || !videoRef.current || !cardData?.id) return;

    canvasRef.current
      .getContext('2d')
      ?.drawImage(
        videoRef.current,
        0,
        0,
        videoRef.current.videoWidth,
        videoRef.current.videoHeight
      );

    const dataUrl = canvasRef.current.toDataURL('image/jpeg');

    const data = await fetch(dataUrl);
    const blob = await data.blob();
    const file = new File([blob], `photo_${format(new Date(), 'HH-mm-ss_dd-MM-yyyy')}.jpeg`);

    return await cardsService.createMediaFileProcesses({
      cardId: cardData.id,
      fileGroup: 'FACE',
      file,
      recordSessionId: currentRecordSessionId,
    });
  }, [cardData?.id, currentRecordSessionId]);

  const takeManualPicture = useCallback(async () => {
    const data = await takePicture();

    if (data?.res) {
      manualModePhotoIds.current.push(data.res.id);
    }
  }, [takePicture]);

  /**
   * изначальный цикл авторежима: запуск + фото каждые 3 секунды, пока не наберется 3 фото.
   */
  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;

    const runAutoModeInitial = () => {
      if (autoModePhotos.current.length < MAX_AUTO_MODE_PHOTOS) {
        timeout = setTimeout(async () => {
          const data = await takePicture();
          if (data?.res) {
            autoModePhotos.current.unshift(data.res);
          }

          runAutoModeInitial();
        }, AUTO_MODE_PHOTO_INTERVAL);
      } else {
        clearTimeout(timeout);
      }
    };

    switch (true) {
      case isRecordingActive && isAutoMode:
        runAutoModeInitial();
        break;

      case isRecordingActive && !isAutoMode:
        clearTimeout(timeout);
        break;

      case !isRecordingActive:
        autoModePhotos.current = [];
        expectedAutoModePhotos.current = Array<null>(MAX_AUTO_MODE_PHOTOS).fill(null);
        manualModePhotoIds.current = [];
        clearTimeout(timeout);
        break;
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isRecordingActive, isAutoMode, takePicture, autoModePhotos.current.length]);

  /**
   * обработка sse-сообщения об изменении в файл-процессе
   */
  useEffect(() => {
    if (!cardFileMessage) return;

    if (cardFileMessage.type === SseMessageType.CardFilesDeleted) {
      manualModePhotoIds.current = manualModePhotoIds.current.filter(
        (id) => !cardFileMessage.fileLinkIds.includes(id)
      );

      /**
       * если для элемента массива autoModePhotos есть элемент под тем же индексом
       * в массиве expectedAutoModePhotos, значит удаление произошло в процессе пересъемки фото.
       * в этом случае ничего не делаем, так как здесь мы хотим обработать удаление как пользовательское действие.
       * и считаем, что удаленные фото (cardFileMessage.fileLinkIds) не могут оказаться
       * в массиве expectedAutoModePhotos, потому что в нем незагруженные фотки и пользователь их не видит.
       */
      autoModePhotos.current = autoModePhotos.current.filter((ph, index) => {
        if (!expectedAutoModePhotos.current[index]) {
          const shouldKeep = !cardFileMessage.fileLinkIds.includes(ph.id);
          if (!shouldKeep) {
            expectedAutoModePhotos.current.splice(index, 1).push(null);
          }

          return shouldKeep;
        }

        return true;
      });
    } else {
      const fileLink = cardFileMessage.fileView;
      if (!fileLink) return;
      /**
       * проверяем есть ли fileLink среди autoModePhotos или expectedAutoModePhotos.
       * заменяем если есть.
       */
      let targetIndex = autoModePhotos.current.findIndex((ph) => fileLink.id === ph.id);
      if (targetIndex > -1) {
        autoModePhotos.current[targetIndex] = fileLink;
      } else {
        targetIndex = expectedAutoModePhotos.current.findIndex(
          (ph) => !!ph && fileLink.id === ph.id
        );
        if (targetIndex > -1) {
          expectedAutoModePhotos.current[targetIndex] = fileLink;
        }
      }

      if (targetIndex < 0) return;

      /**
       * если фотки не видны, то считаем, что фотки, которые мы ждем, загрузились
       */
      if (activeTab !== 'deviceRecording') {
        expectedAutoModePhotos.current = expectedAutoModePhotos.current.map((ph, index) => {
          if (ph) autoModePhotos.current[index] = ph;

          return null;
        });
      }

      /**
       * setAutoModePhotoSseMessage запускает runAutoModeReplace - проверку нужно ли что-то переснять из autoModePhotos.
       * запускаем ее либо когда новое фото только что сделано (status NEW), либо когда его обработка завершена.
       */
      if (fileLink.status !== EFileProcessStatus.INPROGRESS) {
        setAutoModePhotoSseMessage(cardFileMessage);
      }
    }
  }, [cardFileMessage, activeTab]);

  /**
   * второй этап авторежима: дожидаемся обработки фото и переснимаем, если качество ниже 'GOOD',
   * до тех пор, пока у всех фото не будет качество 'GOOD'
   */
  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;

    const runAutoModeReplace = () => {
      if (!cardData?.id) return;
      if (autoModePhotos.current.length < MAX_AUTO_MODE_PHOTOS) return;

      const isAutoModeComplete = autoModePhotos.current.every(
        (ph) =>
          ph.fileModelLinks?.length === 1 &&
          getModelQuality(ph.fileModelLinks[0].fileModel.quality) === 'GOOD'
      );

      if (isAutoModeComplete) {
        clearTimeout(timeout);
        setIsAutoMode(false);
        setIsAutoModeBlocked(true);

        if (!cardData.faceModel) {
          const bestQualityPhoto = autoModePhotos.current.sort((a, b) => {
            if (!b.fileModelLinks || !a.fileModelLinks) return 0;

            return b.fileModelLinks[0].fileModel.quality - a.fileModelLinks[0].fileModel.quality;
          })[0];

          if (!bestQualityPhoto.fileModelLinks) return;

          void cardsService.setCardModel({
            cardId: cardData.id,
            modelId: bestQualityPhoto.fileModelLinks[0].fileModel.id,
          });
        }
      } else {
        const photoToRetake = autoModePhotos.current.find(
          (ph) =>
            ph.status !== EFileProcessStatus.NEW &&
            ph.status !== EFileProcessStatus.INPROGRESS &&
            (ph.fileModelLinks?.length !== 1 ||
              getModelQuality(ph.fileModelLinks[0].fileModel.quality) !== 'GOOD')
        );

        if (!photoToRetake) return;

        const photoIndex = autoModePhotos.current.findIndex((ph) => photoToRetake.id === ph.id);

        timeout = setTimeout(async () => {
          const data = await takePicture();

          if (data?.res) {
            expectedAutoModePhotos.current[photoIndex] = data.res;
          }
        }, AUTO_MODE_PHOTO_INTERVAL);
      }
    };

    if (!isAutoMode || !autoModePhotoSseMessage) {
      clearTimeout(timeout);
    } else {
      runAutoModeReplace();
    }

    return () => clearTimeout(timeout);
  }, [
    activeTab,
    autoModePhotoSseMessage,
    isAutoMode,
    cardData?.id,
    cardData?.faceModel,
    takePicture,
    setIsAutoMode,
    setIsAutoModeBlocked,
  ]);

  useEffect(() => {
    /**
     * проверяем подгрузились ли какие-либо фотки, которые мы ждем.
     * если подгрузились, то обновляем autoModePhotos
     */
    const actualizeVisibleAutoModePhotos = () => {
      expectedAutoModePhotos.current = expectedAutoModePhotos.current.map((item, index) => {
        if (!item) return null;
        if (photos.find((ph) => ph.id === item.id)) {
          const idToDelete = autoModePhotos.current[index].id;
          autoModePhotos.current[index] = item;

          if (!cardData?.id) return null;

          void cardsService.deleteMediaFilesFromCard({
            cardId: cardData.id,
            ids: [idToDelete],
          });

          return null;
        } else {
          return item;
        }
      });
    };

    actualizeVisibleAutoModePhotos();
  }, [photos, activeTab, cardData?.id]);

  return {
    takePicture: takeManualPicture,
    autoModePhotos,
    manualModePhotoIds,
  };
};
