import { createContext, Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import styles from './Groups.module.scss';
import { Separator } from 'stc-ui-kit';
import { observer } from 'mobx-react';
import { GroupsBlock } from './GroupsBlock';
import { IGroup } from '@/types/group';
import groupService from '@/services/group.service';
import { User } from '@/types/user';
import userService from '@/services/user.service';
import { useDataStore } from '@/Providers/StoreProvider';
import { SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_EXPANDED_WIDTH } from '@/components/SideNav/SideNav';
import { useGridStore } from '@/hooks/grid/useGridStore';
import { DataGridStorePredicates, EDataGridStoreSortMode } from '@/types/grid';
import { RightPanelWrapper } from '@/components/RightPanelWrapper';
import { UsersBlockHeader } from './UsersBlockHeader';
import { UsersGrid } from '../Users/components/UsersGrid/UsersGrid';

interface IGroupsPageContext {
  groups: IGroup[];
  fetchGroups: () => Promise<void>;
  fetchGroupUsers: () => void;
  selectedGroupsIds: string[];
  setSelectedGroupsIds: Dispatch<SetStateAction<string[]>>;
  isGroupsLoading: boolean;
}

export const GroupsPageContext = createContext<IGroupsPageContext>({
  groups: [],
  fetchGroups: () => new Promise((res) => res()),
  fetchGroupUsers: () => [],
  selectedGroupsIds: [],
  setSelectedGroupsIds: () => null,
  isGroupsLoading: false,
});

const Groups = observer(() => {
  const [groups, setGroups] = useState<IGroup[]>([]);
  const [isGroupsLoading, setIsGroupsLoading] = useState(false);
  const [selectedGroupsIds, setSelectedGroupsIds] = useState<string[]>([]);
  const selectedGroupId = selectedGroupsIds[0];
  const selectedGroup = groups.find((g) => g.id.toString() === selectedGroupId);

  const { constantsStore } = useDataStore();

  const { store } = useGridStore<User>({
    dataProvider: userService.groupsPageUsersDataProvider,
    defaultSortState: {
      sort: EDataGridStoreSortMode.ASC,
      sortField: 'username',
    },
    withInfiniteScroll: true,
    reloadOnInit: false,
  });

  const fetchGroups = useCallback(async () => {
    setIsGroupsLoading(true);
    try {
      const groups = await groupService.getAll();
      setGroups(groups?.sort((a, b) => a.groupName.localeCompare(b.groupName)) || []);
    } catch (error) {
      console.error(error);
    } finally {
      setIsGroupsLoading(false);
    }
  }, []);

  const fetchGroupUsers = useCallback(() => {
    if (typeof selectedGroupId !== 'string') return;

    store.resetChecked();

    store.applyFilters(undefined, undefined, [
      {
        fieldId: 'department',
        targetValues: [selectedGroupId],
        predicate: DataGridStorePredicates.Equals,
      },
    ]);
  }, [selectedGroupId, store]);

  useEffect(() => {
    void fetchGroups();
  }, [fetchGroups]);

  useEffect(() => {
    void fetchGroupUsers();
  }, [fetchGroupUsers]);

  useEffect(() => {
    if (!selectedGroupsIds.length && groups?.length) {
      setSelectedGroupsIds([groups[0].id.toString()]);
    }
  }, [selectedGroupsIds, groups]);

  return (
    <GroupsPageContext.Provider
      value={{
        fetchGroups,
        fetchGroupUsers,
        groups,
        selectedGroupsIds,
        setSelectedGroupsIds,
        isGroupsLoading,
      }}
    >
      <div
        className={styles.groupsPage}
        style={{
          maxWidth: constantsStore.isSideNavCollapsed
            ? `calc(100% - ${SIDE_NAV_COLLAPSED_WIDTH}px)`
            : `calc(100% - ${SIDE_NAV_EXPANDED_WIDTH}px)`,
        }}
      >
        <GroupsBlock />

        <Separator vertical className={styles.blocksSeparator} />

        {!!selectedGroup && (
          <RightPanelWrapper header={<UsersBlockHeader store={store} />}>
            <UsersGrid store={store} />
          </RightPanelWrapper>
        )}
      </div>
    </GroupsPageContext.Provider>
  );
});

export { Groups };
