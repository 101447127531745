import { UsersGrid } from './components/UsersGrid/UsersGrid';
import { useGridStore } from '@/hooks/grid/useGridStore';
import { User } from '@/types/user';
import { observer } from 'mobx-react';
import { RightPanelWrapper } from '@/components/RightPanelWrapper';
import { HeaderUsers } from './components/HeaderUsers';
import userService from '@/services/user.service';
import { useCallback, useEffect } from 'react';
import { EDataGridStoreSortMode } from '@/types/grid';

export const Users = observer(() => {
  const { store } = useGridStore<User>({
    dataProvider: (state) => userService.usersPageDataProvider(state),
    defaultSortState: {
      sort: EDataGridStoreSortMode.ASC,
      sortField: 'username',
    },
    withInfiniteScroll: true,
    reloadOnInit: false,
  });

  const fetchUsers = useCallback(async () => {
    await store.reload();
  }, [store]);

  useEffect(() => {
    void fetchUsers();
  }, [fetchUsers]);

  return (
    <RightPanelWrapper header={<HeaderUsers store={store} fetchUsers={fetchUsers} />}>
      <UsersGrid store={store} />
    </RightPanelWrapper>
  );
});
