import { FileGroupType } from '@/types/file';
import { EntryTab } from '@/types/ui.types';

export const getFileGroupByTab = (tab: EntryTab, isRecordingActive = false): FileGroupType => {
  switch (tab) {
    case 'face':
      return 'FACE';
    case 'voice':
      return 'VOICE';
    case 'documents':
      return 'DOC';
    case 'attachedFiles':
      return null;
    case 'deviceRecording':
      return isRecordingActive ? 'FACE' : null;
    default:
      return null;
  }
};
