import { Box, Stack } from '@mui/material';
import { DocumentsAndAttachedFilesTable } from './DocumentsAndAttachedFilesTable';
import { ModelsHeader } from '../ModelsHeader/ModelsHeader';
import { useCardCreationContext } from '../../../hooks/useCardCreationContext';
import { useState } from 'react';
import { useFullScreenCardCarousel } from '@/modules/multipass/hooks/useFullScreenCardCarousel';
import { TableRow } from 'stc-ui-kit';
import { fetchCardPhotos } from '../../../utils/fetchCardPhotos';
import { CarouselPhotosContextProvider } from '@/modules/multipass/context/CarouselPhotosContext';
import { ITableItem } from '@/types/file';
import { EntryTab } from '@/types/ui.types';
import { VoiceModelsPlayer } from '../VoiceModels/VoiceModelsPlayer';

export type DocumentsTableTab = Extract<
  EntryTab,
  'documents' | 'attachedFiles' | 'deviceRecording'
>;

export const DocumentsAndAttachedFiles = ({ tab }: { tab: DocumentsTableTab }) => {
  const { fileLinks } = useCardCreationContext();
  const [audioIdForPlayer, setAudioIdForPlayer] = useState<number | null>(null);
  const { carouselRender, loadAndShowPhotos, closeFullScreenCarousel } =
    useFullScreenCardCarousel();

  const imageFiles = fileLinks.filter((file) => file.type === 'img' && file.file?.path);
  const playerAudio = fileLinks.find((fl) => fl.id === audioIdForPlayer);

  const onRowOpen = (item: TableRow<ITableItem>) => {
    switch (item.type) {
      case 'img':
        const index = imageFiles.findIndex((imgFile) => imgFile.id === Number(item.id));

        if (index === -1) {
          return;
        }

        setAudioIdForPlayer(null);

        void loadAndShowPhotos({
          selectedSlideIndex: index,
          fetcher: () =>
            fetchCardPhotos({
              imageFiles,
              withFaceData: tab === 'documents' || tab === 'deviceRecording',
            }),
        });
        break;
      case 'audio':
        if (Number(item.id) === audioIdForPlayer) {
          setAudioIdForPlayer(null);

          return;
        }
        setAudioIdForPlayer(Number(item.id));
        break;
      default:
        return;
    }
  };

  return (
    <Stack direction={'column'} width={'100%'} height={'100%'} position={'relative'}>
      <ModelsHeader />

      <Box height={'100%'} display={'flex'} flexDirection={'column'}>
        <DocumentsAndAttachedFilesTable tab={tab} onRowOpen={onRowOpen} />
      </Box>

      {!!playerAudio?.fileModelLinks && (
        <VoiceModelsPlayer
          selectedProcess={playerAudio}
          onClose={() => setAudioIdForPlayer(null)}
          hideToolbar={tab === 'attachedFiles' || tab === 'documents'}
        />
      )}

      <CarouselPhotosContextProvider onDeletePhoto={closeFullScreenCarousel}>
        {carouselRender}
      </CarouselPhotosContextProvider>
    </Stack>
  );
};
