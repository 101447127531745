import { Box } from '@mui/material';
import { useMemo } from 'react';
import { StcGridTable } from '../Grid/StcGridTable';
import {
  Button,
  ButtonStyle,
  ButtonType,
  EmptyPlaceholder,
  icons,
  TableColumn,
  Tooltip,
} from 'stc-ui-kit';
import { IFileLink, EFileProcessStatus } from '@/types/file';
import { DateCell } from '@/components/queryPage/ExportModal/DateCell';
import { StatusWithIcon } from '@/modules/multipass/StatusWithIcon';
import { useTranslation } from 'react-i18next';
import { ReactComponent as NoFilesPlaceholder } from '@/assets/icons/noFilesPlaceholder.svg';
import { observer } from 'mobx-react';

import { GridStore } from '@/stores/grid.store';
import filesService from '@/services/files.service';

interface Props {
  store: GridStore<IFileLink>;
}

export const FileQueueTable: React.FC<Props> = observer(({ store }) => {
  const { t } = useTranslation();

  const columns: Array<TableColumn<IFileLink>> = useMemo(
    () => [
      {
        id: 'fileName',
        title: t('multipass.fileName'),
        isVisible: true,
        width: 252,
        renderRowCell: (row) => row.filename,
      },
      {
        id: 'createdTime',
        title: t('multipass.additionDate'),
        isVisible: true,
        width: 110,
        renderRowCell: (row) => <DateCell dateTime={row.createdTime ?? ''} component={'span'} />,
      },
      {
        id: 'status',
        title: t('multipass.status'),
        isVisible: true,
        width: 120,
        renderRowCell: (row) => {
          return row.status === EFileProcessStatus.NEW ? (
            <StatusWithIcon status={EFileProcessStatus.INPROGRESS} type={'file'} />
          ) : (
            <StatusWithIcon status={row.status} type={'file'} />
          );
        },
      },
      {
        id: 'delete',
        title: t('delete'),
        isVisible: true,
        width: 80,
        renderRowCell: (row) => (
          <Box display={'flex'} justifyContent={'center'}>
            <Tooltip tooltip={t('delete')}>
              <Button
                onClick={async () => {
                  await filesService.deleteFileById(row.id);
                  await store.reload();
                }}
                icon={icons.IconDelete}
                type={ButtonType.Icon}
                buttonStyle={ButtonStyle.Secondary}
              />
            </Tooltip>
          </Box>
        ),
      },
    ],
    [store, t]
  );

  return (
    <StcGridTable
      store={store}
      columns={columns}
      disableSelection
      noHeader
      tableProps={{
        isBorderDisabled: true,
        renderEmptyPlaceholder: () => (
          <EmptyPlaceholder title={t('multipass.noUploadedFiles')} icon={NoFilesPlaceholder} />
        ),
      }}
    />
  );
});
