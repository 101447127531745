import { Dispatch, SetStateAction, useEffect } from 'react';
import { useDataStore } from '@/Providers/StoreProvider';
import { EFileProcessStatus, IFileLink } from '@/types/file';
import { EntryTab } from '@/types/ui.types';
import { FormReturn } from 'stc-ui-kit';
import { FieldValues } from 'react-hook-form';
import { CardFileSseMessage, SseMessageType } from '@/types/sse';

export const useCardFileSseMessages = ({
  cardId,
  activeTab,
  refetchCardData,
  fileLinks,
  setFileLinks,
  updateTabUiStore,
  refetchTabData,
  formHooks,
}: {
  cardId: number | undefined;
  activeTab: EntryTab;
  refetchCardData: (keepDirty?: boolean) => Promise<void>;
  fileLinks: IFileLink[];
  setFileLinks: Dispatch<SetStateAction<IFileLink[]>>;
  updateTabUiStore: (fileProcesses: IFileLink[], cardId: number) => Promise<void>;
  refetchTabData: () => Promise<void>;
  formHooks: FormReturn<FieldValues>;
}) => {
  const { sseStore, rootMediaDevicesStore } = useDataStore();
  const { currentRecordSessionId, isRecordingActive } = rootMediaDevicesStore;

  // 'visibilitychange' is when we switch browser tabs.
  // when we leave current browser tab sse disconnects,
  // so we need to refetch data when coming back.
  useEffect(() => {
    const onVisibilityChange = async () => {
      if (document.visibilityState === 'visible') {
        await refetchData();
      }
    };

    document.addEventListener('visibilitychange', onVisibilityChange);

    return () => document.removeEventListener('visibilitychange', onVisibilityChange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardId, activeTab]);

  useEffect(() => {
    if (sseStore.isReconnectedAfterError) {
      sseStore.setIsReconnectedAfterError(false);
      void refetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sseStore.isReconnectedAfterError]);

  const refetchData = async () => {
    await refetchCardData(formHooks.formState.isDirty);
    await refetchTabData();
  };

  const checkIfCurrentTab = (message: CardFileSseMessage, fileLinks: IFileLink[]) => {
    if (message.type === SseMessageType.CardFilesDeleted) {
      return message.fileLinkIds?.every((id) => fileLinks.find((l) => l.id === id));
    }

    const fileGroup = message.fileView?.fileGroup;

    switch (activeTab) {
      case 'face':
        return fileGroup === 'FACE';
      case 'voice':
        return fileGroup === 'VOICE';
      case 'documents':
        return fileGroup === 'DOC';
      case 'attachedFiles':
        return fileGroup === null;
      case 'deviceRecording':
        return isRecordingActive
          ? message.fileView?.recordSessionId === currentRecordSessionId
          : message.fileView?.recordSessionId !== null;
    }
  };

  useEffect(() => {
    const message = sseStore.cardFileMessage;
    if (!message) return;
    if (message.cardId !== cardId) return;

    const processSseMessage = () => {
      let updatedFileLinks: IFileLink[] = [];
      const prev = fileLinks;
      const isCurrentTab = checkIfCurrentTab(message, prev);

      if (message.type === SseMessageType.CardFilesDeleted) {
        if (!isCurrentTab) return prev;

        updatedFileLinks = prev.filter((l) => !message.fileLinkIds?.includes(l.id));
      } else {
        if (!message.fileView) return prev;
        if (!isCurrentTab) return prev;

        updatedFileLinks = [...prev];
        const linkIndex = updatedFileLinks.findIndex((l) => l.id === message.fileView?.id);
        linkIndex > -1
          ? (updatedFileLinks[linkIndex] = message.fileView)
          : updatedFileLinks.unshift(message.fileView);
      }

      setFileLinks(updatedFileLinks);

      /**
       * есть смысл обновлять uiStore только при создании файл-процесса,
       * либо при завершении его обработки.
       */
      if (
        message.type === SseMessageType.CardFileUpdated &&
        message.fileView?.status === EFileProcessStatus.INPROGRESS
      ) {
        return;
      }

      void updateTabUiStore(updatedFileLinks, cardId);
    };

    processSseMessage();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sseStore.cardFileMessage]);
};
