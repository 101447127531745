import { useEffect, useRef } from 'react';
import { useBlocker } from 'react-router-dom';
import { ConfirmationModalProps, ConfirmationType, useConfirmationModal } from 'stc-ui-kit';

interface IBlockerSetup {
  when: boolean;
  onSave?: () => Promise<unknown>;
  confirmationModalProps: ConfirmationModalProps;
}

export const useUnsavedChangesModal = (setups: IBlockerSetup[]) => {
  const { showConfirmationModal } = useConfirmationModal();
  /**
   * копия нужна для отображения нескольких модалок подряд,
   * чтобы отслеживать какие уже были показаны
   */
  const setupsCopy = useRef([...setups]);
  const canBeBlocked = setups.some((s) => s.when);

  const blocker = useBlocker(canBeBlocked);

  useEffect(() => {
    if (blocker.state === 'blocked' && canBeBlocked) {
      setupsCopy.current = setups;
      void showUnsavedChangesModal();
    } else {
      blocker.proceed?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blocker.state]);

  const checkAndProceed = () => {
    setupsCopy.current.some((s) => s.when) ? void showUnsavedChangesModal() : blocker.proceed?.();
  };

  const showUnsavedChangesModal = async () => {
    const index = setupsCopy.current.findIndex((s) => s.when);
    if (index < 0) return;
    const setup = setupsCopy.current[index];
    setupsCopy.current.splice(index, 1);

    const submitType = await showConfirmationModal(setup.confirmationModalProps);

    switch (submitType) {
      case ConfirmationType.PrimaryAction:
        const error = await setup.onSave?.();
        if (error) {
          blocker.reset?.();

          return;
        }
        checkAndProceed();
        break;

      case ConfirmationType.SecondaryAction:
        checkAndProceed();
        break;

      case ConfirmationType.Cancel:
        blocker.reset?.();
        break;
    }
  };
};
