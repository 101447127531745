import { Dispatch, SetStateAction, useState } from 'react';
import filesApiService from '@/services/files.service';
import { Stack, TextField, Typography } from '@mui/material';
import { Button, ButtonStyle, ButtonType, icons } from 'stc-ui-kit';

import styles from './FileInfo.module.scss';
import { useTranslation } from 'react-i18next';
import { BiometricItem } from '@/types/ui.types';
import { IFileLink, IMediaData } from '@/types/file';
import { convertSecondsToTime } from '@/utils/convertSecondsToTime';
import { formatDateTimeString } from '@/utils/formatDateTimeString';
import { useUserPermissionsContext } from '@/hooks/useUserPermissionsContext';
import { permissionControl } from '@/types/permissionControl';

interface IData {
  label: string;
  value: number | string | undefined;
  isComment?: boolean;
}

export const FileInfo = ({
  tab,
  onClose,
  fileInfo,
  cardId,
  setFileInfo,
  additionalAudioInfo,
  isModelInFile,
}: {
  tab: BiometricItem;
  onClose: () => void;
  fileInfo: IFileLink | null;
  cardId: number;
  setFileInfo: Dispatch<SetStateAction<IFileLink | null>>;
  additionalAudioInfo: IMediaData | null;
  isModelInFile: boolean;
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [comment, setComment] = useState(fileInfo?.comment || '');
  const { havePermissions } = useUserPermissionsContext();

  const { t } = useTranslation();

  const getAudioData = () => {
    const metadata = fileInfo?.fileProcessMetadata?.metadataItems[0].data;

    const audioData: IData[] = [
      {
        label: t('player.speechDuration'),
        value:
          typeof metadata?.totalTimeSpeech === 'number'
            ? convertSecondsToTime(metadata.totalTimeSpeech)
            : '—',
      },
      {
        label: t('player.signalAndNoise'),
        value:
          typeof additionalAudioInfo?.snr === 'number'
            ? `${additionalAudioInfo.snr.toFixed(2)} ${t('player.dBUnit')}`
            : '—',
      },
      {
        label: t('player.reverberation'),
        value:
          typeof additionalAudioInfo?.rtAver === 'number'
            ? additionalAudioInfo.rtAver.toFixed(2)
            : '—',
      },
      {
        label: t('player.audioDuration'),
        value: metadata?.durationSec ? convertSecondsToTime(metadata.durationSec) : '—',
      },
      {
        label: t('player.fileSize'),
        value:
          typeof fileInfo?.file?.size === 'number'
            ? `${fileInfo.file.size} ${t('player.byteUnit')}`
            : '—',
      },
      {
        label: t('player.samplingRate'),
        value:
          typeof metadata?.sampleRate === 'number'
            ? `${metadata.sampleRate} ${t('player.hzUnit')}`
            : '—',
      },
      {
        label: t('player.encodingType'),
        value: metadata?.sampleFormatName || '—',
      },
    ];

    if (isModelInFile) {
      audioData.push({
        label: t('player.segmentsLength'),
        value:
          typeof additionalAudioInfo?.timeSpeech === 'number'
            ? convertSecondsToTime(additionalAudioInfo.timeSpeech)
            : '—',
      });
    }

    const rest = [
      { label: t('player.algorithm'), value: additionalAudioInfo?.algorithm || '—' },
      {
        label: t('player.quality'),
        value:
          typeof additionalAudioInfo?.quality === 'number'
            ? additionalAudioInfo?.quality.toFixed(2)
            : '—',
      },
      {
        label: t('player.lowerBound'),
        value:
          typeof additionalAudioInfo?.lowestSpeechFreqHz === 'number'
            ? `${additionalAudioInfo.lowestSpeechFreqHz.toFixed(2)} ${t('player.hzUnit')}`
            : '—',
      },
      {
        label: t('player.upperBound'),
        value:
          typeof additionalAudioInfo?.highestSpeechFreqHz === 'number'
            ? `${additionalAudioInfo.highestSpeechFreqHz.toFixed(2)} ${t('player.hzUnit')}`
            : '—',
      },
      {
        label: t('player.coe'),
        value:
          typeof additionalAudioInfo?.rtRms === 'number'
            ? additionalAudioInfo.rtRms.toFixed(2)
            : '—',
      },
      {
        label: t('player.reverberationMoments'),
        value: typeof additionalAudioInfo?.rtCount === 'number' ? additionalAudioInfo.rtCount : '—',
      },
      {
        label: t('player.noiseFragmentsLength'),
        value:
          typeof additionalAudioInfo?.timeTonalNoise === 'number'
            ? convertSecondsToTime(additionalAudioInfo.timeTonalNoise)
            : '—',
      },
      {
        label: t('player.clipFragmentsLength'),
        value:
          typeof additionalAudioInfo?.clippingRanges === 'number'
            ? convertSecondsToTime(additionalAudioInfo.clippingRanges)
            : '—',
      },
      {
        label: t('player.maxLengthNoClip'),
        value:
          typeof additionalAudioInfo?.maxUnclippedInterval === 'number'
            ? convertSecondsToTime(additionalAudioInfo.maxUnclippedInterval)
            : '—',
      },
    ];

    audioData.push(...rest);

    return audioData;
  };

  const getImageData = (): IData[] => [
    {
      label: `${t('multipass.createdDateTime')}:`,
      value: formatDateTimeString(fileInfo?.createdTime),
    },
    { label: `${t('multipass.addedBy')}:`, value: fileInfo?.creator?.username || '—' },
    {
      label: `${t('multipass.editedDateTime')}:`,
      value: formatDateTimeString(fileInfo?.editedTime),
    },
    { label: `${t('cards.changedBy')}:`, value: fileInfo?.editor?.username || '—' },
    {
      label: `${t('commentary')}:`,
      value: fileInfo?.comment || '—',
      isComment: true,
    },
  ];

  const data = tab === 'face' ? getImageData() : getAudioData();

  const handleSaveComment = async () => {
    if (tab === 'face' && !isEditing) {
      setIsEditing(!isEditing);
    } else if (isEditing) {
      if (!fileInfo) return;

      await filesApiService.createCommentFile(comment || null, cardId, fileInfo.id);

      setIsEditing(false);
      setFileInfo({ ...fileInfo, comment: comment });
    } else {
      onClose();
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <Typography className={styles.title}>
          {tab === 'face' ? t('multipass.infoPhoto') : t('multipass.infoAudio')}
        </Typography>
        <Button
          onClick={onClose}
          data-testid='close-map'
          type={ButtonType.Icon}
          buttonStyle={ButtonStyle.Secondary}
          icon={icons.IconClose24}
          tooltip={t('close')}
        />
      </div>
      <div className={styles.content}>
        {data.map((item, index) => (
          <Stack
            key={index}
            flexDirection={item?.isComment && isEditing ? 'column' : 'row'}
            gap='16px'
          >
            <Typography
              className={styles.label}
              sx={{
                mt: index !== 0 ? '16px' : 0,
              }}
            >
              {item.label}
            </Typography>
            {item?.isComment && isEditing ? (
              <TextField
                fullWidth
                multiline
                rows={3}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                className={styles.textField}
              />
            ) : (
              <Typography
                sx={{ mt: index !== 0 && !isEditing ? '16px' : 0 }}
                className={styles.value}
              >
                {item.value}
              </Typography>
            )}
          </Stack>
        ))}
      </div>
      <div
        className={styles.footer}
        style={{
          justifyContent: tab !== 'face' ? 'space-between' : 'flex-end',
        }}
      >
        {tab === 'voice' && (
          <Button icon={icons.IconInfo} isDisabled type={ButtonType.Text}>
            {t('multipass.recommendedData')}
          </Button>
        )}

        {tab === 'face' &&
        havePermissions(
          permissionControl.enrollment.cardCreation.modelsSection.editFileInfoButton
        ) ? (
          <Button icon={isEditing ? icons.IconSave : icons.IconEdit} onClick={handleSaveComment}>
            {isEditing ? t('save') : t('edit')}
          </Button>
        ) : (
          <Button onClick={onClose}>{t('close')}</Button>
        )}
      </div>
    </div>
  );
};
