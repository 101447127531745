import { GridStore } from '@/stores/grid.store';
import { ICardsGridItem } from '@/types/card';
import { Box, SvgIcon } from '@mui/material';
import { appColors } from '@/Providers/ThemeProvider/colors';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { GridCellWrapperWithTooltip } from './GridCellWrapperWithTooltip';
import { StcGridTable } from '@/components/Grid/StcGridTable';
import { MultipassDuplicateIcon } from '@/modules/multipass/icons/Duplicate';
import moment from 'moment';
import { Icon, icons, TableColumn, Tooltip, UiKitColor } from 'stc-ui-kit';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@/types';
import { createCardEditLink } from '@/modules/multipass/utils/cardCreationLinks';
import { FieldDescriptor } from '@/types/cardTemplate';
import { StatusWithIcon } from '@/modules/multipass/StatusWithIcon';
import CardModelsAvatars from '@/components/FileDialog/CardModelsAvatars';
import { ReactComponent as AttachIcon } from '@/assets/icons/attachFile.svg';
import { DateCell } from '@/components/queryPage/ExportModal/DateCell';

interface Props {
  store: GridStore<ICardsGridItem>;
  systemFields: FieldDescriptor[];
}

export const CardsGrid: React.FC<Props> = (props) => {
  const { store, systemFields } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const columns = useMemo(() => {
    const result: TableColumn<ICardsGridItem>[] = [
      {
        id: t('multipass.tooltipDuplicate'),
        width: 56,
        title: t('multipass.tooltipDuplicate'),
        renderHeaderCell: () => {
          return (
            <Tooltip tooltip={t('multipass.tooltipDuplicate')}>
              <Box position={'relative'} bottom={'-1px'}>
                <MultipassDuplicateIcon />
              </Box>
            </Tooltip>
          );
        },
        renderRowCell(row) {
          if (!row.isDuplicate) return null;

          return (
            <Tooltip tooltip={t('multipass.tooltipDuplicate')}>
              <SvgIcon
                sx={{
                  color: appColors.grey800,
                }}
              >
                <MultipassDuplicateIcon />
              </SvgIcon>
            </Tooltip>
          );
        },
        isVisible: true,
      },
      {
        id: 'hotList',
        width: 56,
        title: t('multipass.isInHotList'),
        renderHeaderCell: () => {
          return (
            <Tooltip tooltip={t('multipass.isInHotList')}>
              <Icon icon={icons.IconTaskHotControl} />
            </Tooltip>
          );
        },
        isVisible: true,
        renderRowCell(row) {
          return row.isInHotlist ? (
            <Tooltip tooltip={t('multipass.isInHotList')}>
              <Icon icon={icons.IconTaskHotControl} iconColor={UiKitColor.Red700} />
            </Tooltip>
          ) : (
            ''
          );
        },
      },
      {
        id: 'models',
        width: 150,
        title: t('cards.models'),
        isVisible: true,
        renderRowCell(row) {
          const photoModel = row.faceModel;
          const photoPath = row.photoPath || '';
          const voiceModel = row.voiceModel;

          return (
            <Box display={'flex'} alignItems={'center'}>
              <CardModelsAvatars
                photoPath={photoPath}
                faceModelQuality={photoModel?.quality}
                voiceModelQuality={voiceModel?.quality}
              />
            </Box>
          );
        },
      },
      {
        id: 'status',
        title: t('multipass.status'),
        width: 150,
        isVisible: true,
        renderRowCell(row) {
          const status = row.exportStatus;

          return <StatusWithIcon status={status} type={'card'} />;
        },
      },
      {
        id: 'totalFiles',
        width: 60,
        title: t('cards.documentAvailabilityIndicator'),
        renderHeaderCell: () => {
          return (
            <Tooltip tooltip={t('cards.documentAvailabilityIndicator')}>
              <SvgIcon
                sx={{
                  color: appColors.darkLightGrey,
                }}
              >
                <AttachIcon />
              </SvgIcon>
            </Tooltip>
          );
        },
        renderRowCell(row) {
          if (row.totalFiles > 0) {
            return (
              <Tooltip tooltip={t('cards.documentAvailabilityIndicator')}>
                <SvgIcon
                  sx={{
                    color: appColors.darkLightGrey,
                  }}
                >
                  <AttachIcon />
                </SvgIcon>
              </Tooltip>
            );
          }

          return null;
        },
        isVisible: true,
      },
    ];

    for (const systemField of systemFields) {
      result.push({
        id: systemField.id,
        width: 150,
        title: systemField.name,
        renderRowCell: (row) => {
          const valueObject = row.data ? row.data[systemField.id] : '';
          let value = '';
          if (valueObject) {
            value = String(valueObject);
            if (
              (systemField.type === 'Date' || systemField.type === 'DateTime') &&
              !!systemField.formatter
            ) {
              value = moment(value).format(systemField.formatter);
            }
          }

          return <GridCellWrapperWithTooltip value={value} />;
        },
        isVisible: true,
      });
    }

    result.push(
      {
        id: 'editedOnDateTime',
        width: 150,
        title: t('cards.changed'),
        renderRowCell: (row) => (
          <DateCell dateTime={row.editedOnDateTime ?? ''} component={'span'} />
        ),
        isVisible: true,
      },
      {
        id: 'editorName',
        width: 150,
        title: t('cards.changedBy'),
        isVisible: true,
        renderRowCell: (row) => {
          const value: string = row.editorName || '';

          return <GridCellWrapperWithTooltip value={value} />;
        },
      },
      {
        id: 'resource',
        width: 200,
        title: t('multipass.resource'),
        isVisible: true,
        renderRowCell: (row) => {
          return <GridCellWrapperWithTooltip value={row.sourceFiles?.join(', ') || ''} />;
        },
      }
    );

    return result;
  }, [t, systemFields]);

  return (
    <StcGridTable
      store={store}
      columns={columns}
      tableProps={{
        onRowDoubleClick: (item) =>
          navigate(createCardEditLink({ id: item.id, from: ROUTES.MULTIPASS_FILLING_CABINET })),
      }}
    />
  );
};
