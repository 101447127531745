import { cardsService } from '@/services/cards.service';
import { IApiFieldFilter } from '@/types';
import { FileGroupType } from '@/types/file';

export const fetchFileLinks = async (
  cardId: number,
  fileGroup: FileGroupType,
  additionalFilters?: IApiFieldFilter[]
) => {
  try {
    const { res } = await cardsService.getMediaFileProcesses({
      cardId: cardId,
      fileGroup: fileGroup ? [fileGroup] : undefined,
      additionalFilters,
    });

    return res;
  } catch (error) {
    console.error(error);
  }
};
