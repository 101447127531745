import { FileType } from './file';

type VoiceModel = {
  type: 'audio';
  data: VoiceData;
};

type PhotoModel = {
  type: 'img';
  data: FaceData;
};

type FileModelLink = {
  id: number;
  modelSourceId: number;
  fileProcessId: number;
  mediaId: number;
  cardMediaFileLinkId: number;
};

export type ModelFileType = 'FACE_MODEL_SOURCE' | 'FACE_NORMALIZED' | 'VOICE_MODEL_SOURCE';

type FaceModelFileData = {
  uri: string;
  mediaFileId: number;
  correlationId: string;
};

export interface FaceData {
  files: FaceModelFile[];
  area: { h: number; w: number; x: number; y: number };
  portraitCharacteristics: PortraitCharacteristics[];
}

type FaceModelFile = {
  type: 'FACE_MODEL_SOURCE' | 'FACE_NORMALIZED';
  data: FaceModelFileData;
};

export interface VoiceData {
  files: VoiceModelFile[];
  totalSpeechTimeSec: number;
  modelState: string;
}

type VoiceModelFile = {
  type: 'VOICE_MODEL_SOURCE';
  data: VoiceModelFileData;
};

export type VoiceModelFileData = {
  uri: string;
  mediaFileId: number;
  correlationId: string;
  channelType: string;
  channelNumber: string;
  speakerNumberInChannel: number;
  speechTimeSec: number;
  isManual: boolean | null;
};

export type Model = {
  id: number;
  quality: number;
  type: FileType;
  fileModelLinks: FileModelLink[];
} & (VoiceModel | PhotoModel);

export enum EPortraitCharacteristicsQuality {
  HIGH = 'High',
  MEDIUM = 'Medium',
  LOW = 'Low',
  UNDEFINED = 'Undefined',
}

export interface PortraitCharacteristics {
  quality: EPortraitCharacteristicsQuality;
  characteristic: string;
}
