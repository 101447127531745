import { FC, useEffect, useMemo, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import BlockIcon from '@mui/icons-material/Block';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {
  Button,
  ButtonType,
  Checkbox,
  ConfirmationType,
  Separator,
  Tooltip,
  icons,
  useConfirmationModal,
} from 'stc-ui-kit';

import { HeaderLeftIconTextWrapper } from '@/components/HeaderLeftIconTextWrapper';

import { User } from '@/types/user';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CreateUserModal } from './CreateUserModal';
import userService from '@/services/user.service';
import { useDataStore } from '@/Providers/StoreProvider';
import { GridStore } from '@/stores/grid.store';
import { observer } from 'mobx-react';
import { ChangePasswordUserModal } from './ChangePasswordUserModal';
import { useUserPermissionsContext } from '@/hooks/useUserPermissionsContext';
import { permissionControl } from '@/types/permissionControl';
import { showInfoNotification } from '@/utils/notifications';

interface IHeaderUsersProps {
  store: GridStore<User>;
  fetchUsers: () => Promise<void>;
}

export const HeaderUsers: FC<IHeaderUsersProps> = observer((props) => {
  const {
    userStore: { userData },
  } = useDataStore();
  const userId = userData.id;
  const { store, fetchUsers } = props;
  const { havePermissions } = useUserPermissionsContext();
  const { createButton, editButton, changePasswordButton, blockButton, unblockButton } =
    permissionControl.administration.users;
  const checkedItems = store.checkedItems;

  const { t } = useTranslation();
  const [createUserModalOpened, setCreateUserModalOpened] = useState(false);
  const [changePasswordModalOpened, setChangePasswordModalOpened] = useState(false);

  const [editableUser, setEditableUser] = useState<User | null>(null);

  const [countUsers, setCountUsers] = useState(0);

  const selectedCount = checkedItems.length;

  useEffect(() => {
    userService
      .getUserCount()
      .then((count) => {
        if (count) {
          setCountUsers(count);
        }
      })
      .catch((err) => console.error(err));
  }, [store.dataProvider]);

  const blockYourself = useMemo(
    () => checkedItems.map((i) => Number(i.id)).includes(+userId),
    [checkedItems, userId]
  );

  const blockDisabled = useMemo(
    () => selectedCount === 0 || !userId || blockYourself,
    [blockYourself, selectedCount, userId]
  );

  const { showConfirmationModal } = useConfirmationModal();

  const blockOrUnblockUser = async (actionType: 'block' | 'unblock') => {
    const userNames = checkedItems.map((u) => `«${u.username}»`).join(', ');

    const submitType = await showConfirmationModal({
      title: actionType === 'block' ? t('blockUser') : t('unblockUser'),
      text:
        actionType === 'block'
          ? t('youSureWantBlockUsers', { name: userNames })
          : t('youSureWantUnblockUsers', { name: userNames }),
      cancelButtonText: t('cancel'),
      primaryButtonText: actionType === 'block' ? t('block') : t('unblock'),
    });

    if (submitType === ConfirmationType.PrimaryAction) {
      const actionFunction =
        actionType === 'block' ? userService.disableUser : userService.enableUser;

      try {
        await Promise.all(checkedItems.map((user) => actionFunction(user.id)));
        showInfoNotification({
          header: t(actionType === 'block' ? 'messages.usersBlocked' : 'messages.usersUnblocked'),
        });

        await fetchUsers();
        store.resetChecked();
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <>
      <HeaderLeftIconTextWrapper icon={icons.IconPerson} text={t('users')} border />

      {havePermissions(createButton) && (
        <Box marginX='16px'>
          <Button icon={() => <AddIcon />} onClick={() => setCreateUserModalOpened(true)}>
            {t('createUser')}
          </Button>
        </Box>
      )}

      <Separator vertical />
      <Box marginX={'16px'}>
        <Checkbox
          label={`${t('selected')}: ${selectedCount}/${countUsers}`}
          isChecked={selectedCount > 0}
          onChange={() => {
            store.resetChecked();
          }}
          data-testid=''
        />
      </Box>

      <Separator vertical />

      <Box marginLeft='10px' display='flex' gap='16px'>
        {havePermissions(editButton) && (
          <Tooltip tooltip={t('edit')}>
            <span>
              <Button
                icon={icons.IconEdit}
                type={ButtonType.Icon}
                isDisabled={selectedCount !== 1}
                onClick={() => {
                  setEditableUser(checkedItems[0]);
                  setCreateUserModalOpened(true);
                }}
              />
            </span>
          </Tooltip>
        )}
        {havePermissions(changePasswordButton) && (
          <Tooltip tooltip={t('changePassword')}>
            <span>
              <Button
                icon={icons.IconPassword}
                type={ButtonType.Icon}
                isDisabled={selectedCount !== 1}
                onClick={() => {
                  setEditableUser(checkedItems[0]);
                  setChangePasswordModalOpened(true);
                }}
              />
            </span>
          </Tooltip>
        )}
        {havePermissions(blockButton) && (
          <Tooltip tooltip={blockYourself ? t('blockYourself') : t('block')}>
            <span>
              <Button
                icon={() => <BlockIcon />}
                type={ButtonType.Icon}
                isDisabled={blockDisabled}
                onClick={() => blockOrUnblockUser('block')}
              />
            </span>
          </Tooltip>
        )}
        {havePermissions(unblockButton) && (
          <Tooltip tooltip={t('unblock')}>
            <span>
              <Button
                icon={() => <RemoveCircleOutlineIcon />}
                type={ButtonType.Icon}
                isDisabled={selectedCount === 0}
                onClick={() => blockOrUnblockUser('unblock')}
              />
            </span>
          </Tooltip>
        )}
        <CreateUserModal
          store={store}
          fetchUsers={fetchUsers}
          isOpened={createUserModalOpened}
          onClose={() => {
            setEditableUser(null);
            setCreateUserModalOpened(false);
          }}
          initialUserData={editableUser}
        />
        {editableUser && (
          <ChangePasswordUserModal
            store={store}
            fetchUsers={fetchUsers}
            isOpened={changePasswordModalOpened}
            onClose={() => {
              setEditableUser(null);
              setChangePasswordModalOpened(false);
            }}
            initialUserData={editableUser}
          />
        )}
      </Box>
    </>
  );
});
