import { exportStatusId } from '@/modules/multipass/FilingCabinet/components/SideFilter/constants';
import { ECardExportStatus, ICardsGridItem } from '@/types/card';
import { DataGridStorePredicates, IGridFilter } from '@/types/grid';
import {
  IOpenedCard,
  CardTab,
  SortOrder,
  BiometricItem,
  EntryTab,
  TabEntity,
} from '@/types/ui.types';
import { makeObservable, observable, action, ObservableMap } from 'mobx';

export class UiStore {
  constructor() {
    makeObservable(this);
  }

  @observable
  openedCards: ObservableMap<number, IOpenedCard> = observable.map();

  @observable filingCabinetFilters: {
    quickFilters: IGridFilter<ICardsGridItem>[];
    searchString: string;
  } = {
    quickFilters: [],
    searchString: '',
  };

  getOpenedCard(cardId: number): IOpenedCard | undefined {
    return this.openedCards.get(cardId);
  }

  @action
  setQuickFilters = (filters: IGridFilter<ICardsGridItem>[]) => {
    this.filingCabinetFilters.quickFilters = filters;
  };

  @action
  setExportStatusQuickFilter = (value: ECardExportStatus[] | null) => {
    const updatedFilters = [...this.filingCabinetFilters.quickFilters];
    if (!value || !value.length) {
      this.filingCabinetFilters.quickFilters = updatedFilters.filter(
        (f) => f.fieldId !== exportStatusId
      );

      return;
    }

    const statusFilterIndex = updatedFilters.findIndex((f) => f.fieldId === exportStatusId);
    updatedFilters[statusFilterIndex > -1 ? statusFilterIndex : updatedFilters.length] = {
      fieldId: exportStatusId,
      targetValues: value,
      predicate: DataGridStorePredicates.Contains,
    };

    this.filingCabinetFilters.quickFilters = updatedFilters;
  };

  @action
  setSearchString = (search: string) => {
    this.filingCabinetFilters.searchString = search;
  };

  @action
  saveOpenedCard(card: IOpenedCard) {
    this.openedCards.set(card.cardId, card);
  }

  @action
  initOpenedCard(cardId: number) {
    const initialSortOrder = new Map<EntryTab, SortOrder>();
    initialSortOrder.set('face', 'DESC');
    initialSortOrder.set('voice', 'DESC');
    initialSortOrder.set('documents', 'DESC');
    initialSortOrder.set('attachedFiles', 'DESC');

    const initialSelectedEntities = new Map<EntryTab, number[]>();
    initialSelectedEntities.set('face', []);
    initialSelectedEntities.set('voice', []);
    initialSelectedEntities.set('documents', []);
    initialSelectedEntities.set('attachedFiles', []);

    const initialTabEntities = new Map<EntryTab, TabEntity[]>();
    initialTabEntities.set('face', []);
    initialTabEntities.set('voice', []);
    initialTabEntities.set('documents', []);
    initialTabEntities.set('attachedFiles', []);

    this.saveOpenedCard({
      cardId,
      activeTab: 'biometricModels',
      activeSideMenuItem: 'face',
      sideMenuCollapsed: false,
      selectedEntities: initialSelectedEntities,
      entities: initialTabEntities,
      sortOrder: initialSortOrder,
    });
  }

  @action
  closeCard(cardId: number) {
    this.openedCards.delete(cardId);
  }

  @action
  clear() {
    this.openedCards.clear();
  }

  @action
  setActiveTab(cardId: number, tab: CardTab) {
    const card = this.getOpenedCard(cardId);
    if (card) {
      card.activeTab = tab;
    }
  }

  @action
  setActiveSideMenuItem(cardId: number, menuItem: BiometricItem) {
    const card = this.getOpenedCard(cardId);
    if (card) {
      card.activeSideMenuItem = menuItem;
    }
  }

  @action
  setSideMenuCollapsed(cardId: number, collapsed: boolean) {
    const card = this.getOpenedCard(cardId);
    if (card) {
      card.sideMenuCollapsed = collapsed;
    }
  }

  @action
  setSelectedEntities(cardId: number, section: EntryTab, entities: number[]) {
    const card = this.getOpenedCard(cardId);
    if (card) {
      card.selectedEntities.set(section, entities);
    }
  }

  @action
  setEntities(cardId: number, section: EntryTab, entities: TabEntity[]) {
    const card = this.getOpenedCard(cardId);
    if (card) {
      const newEntityIds = entities.map((entity) => entity.id);
      const currentSelectedEntities = card.selectedEntities.get(section) || [];
      const filteredSelectedEntities = currentSelectedEntities.filter((id) =>
        newEntityIds.includes(id)
      );
      card.selectedEntities.set(section, filteredSelectedEntities);
      card.entities.set(section, entities);
    }
  }

  @action
  setSortOrder(cardId: number, section: EntryTab, order: SortOrder) {
    const card = this.getOpenedCard(cardId);
    if (card) {
      card.sortOrder.set(section, order);
    }
  }
}
